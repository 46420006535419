import React, { FunctionComponent, useRef, useMemo } from 'react'
import { Card, Table } from 'antd'
import { getTableXwidth } from '@configs/table'
import { ColumnProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, UserAvatar } from '@components'
import { configScrmFlowLogFilterItems } from '../../../components/CommonFilters/configs'
import { getPrivateAreaBindingRecordList } from '@services/customer'
import { useTableService } from '@hooks/useTableService'
import {
  ListRobotCallRollItem,
} from '@types'
import { privateSendStatusMap } from '@configs/map'
import { Link } from 'react-router-dom'
import { trimStr } from '@library'

const ConfigRobotTaskLogPage: FunctionComponent = () => {
  const filterRef = useRef()
  const initFilter = {
    page: 1,
    pageSize: 10,
  }

  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
    rowKey,
    // reload,
  } = useTableService(getPrivateAreaBindingRecordList, initFilter, '获取关联流程记录失败')

  const columns: ColumnProps<ListRobotCallRollItem>[] = useMemo(() => [
    { title: '加微时间', dataIndex: 'createTime', width: 100 },
    { title: '微信昵称', dataIndex: 'wxNickName', width: 100 },
    {
      title: '微信unionid',
      dataIndex: 'unionId',
      width: 120,
    },
    {
      title: '添加人',
      dataIndex: 'addSales',
      width: 140,
      render: value => <UserAvatar salesName={value} />,
    },
    { title: '添加人职务', dataIndex: 'addSalesPosition', width: 90 },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      width: 100,
    },
    {
      title: '发送状态',
      dataIndex: 'sendStatus',
      width: 80,
      render: (v) => privateSendStatusMap[v],
    },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  const expandedColumns = useMemo(() => [
    { title: 'UIN', dataIndex: 'uin', width: 100 },
    { title: 'CID', dataIndex: 'cid', width: 135, render: (v) => <div style={{ width: 135 }}>{v}</div> },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: '是否再分配', dataIndex: 'isAssign', width: 120, render: (v) => ['否', '是']?.[v] },
    { title: '当前跟进人', dataIndex: 'follower', width: 120 },
    { title: '所属群组', dataIndex: 'groupName', width: 150, render: (v) => <div style={{ wordBreak: 'break-all' }}>{v}</div> },
    { title: '所属公司', dataIndex: 'companyName', width: 150 },
  ], [])

  const expandedRowRender = (record) => (
    <div style={{ marginLeft: 60 }}>
      <Table
        columns={expandedColumns}
        dataSource={record?.referUinList}
        pagination={false}
      />
    </div>
  )

  return (
    <>
      <PageHeader title="私域关联流程记录" />
      <Card className="mt20">
        <CommonFilters
          cRef={filterRef}
          colProps={{ flex: '1 1 300px' }}
          itemList={configScrmFlowLogFilterItems}
          onSubmit={filter => {
            console.log('==============filter==========', filter)
            setFilter({ ...filter, page: 1 })
          }}
          onReset={() => resetFilter(initFilter)}
          standstill={8}
        />
      </Card>
      <Card className="mt20">
        <Table
          loading={loading}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          onChange={() => console.log('change')}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          tableLayout="fixed"
          className="mt20"
          expandable={{ expandedRowRender: (record) => expandedRowRender(record), defaultExpandedRowKeys: ['0'] }}
        />
      </Card>
    </>
  )
}

export default ConfigRobotTaskLogPage
