/**
 * 目标管理
 * @author 郑巡卫
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { UserOutlined } from '@ant-design/icons'
import { Card, Empty, message, Tree } from 'antd'
import { CommonFilters, PageHeader } from '@components'
import { useObject, useService, useShowError } from '@hooks'
import { Goal, GoalFilter, GoalRelatedTypeEnum } from '@types'
import { goalFilterItems } from '../../../components/CommonFilters/configs'
import { isArray, isEmpty, notEmptyArray } from '@library'
import { getGoal } from '@services/task'
import store from '@store'
import GoalElement from '@pages/task/goal/GoalElement'
import { EventDataNode, DataNode } from 'antd/lib/tree'

const initFilterValue = { belongMonth: moment() }
const initFilter: GoalFilter = {
  belongMonth: initFilterValue.belongMonth.startOf('month').format('YYYY-MM'),
  relatedId: undefined,
  relatedType: undefined,
}

export interface GoalNode extends Goal {
  readonly key: string
  readonly parentKey: string
  readonly simpleMode?: boolean // 仅显示label
  children?: GoalNode[]
}

const toGoalNode = (goalList: Goal[], parent?: GoalNode): GoalNode[] => {
  if (isEmpty(goalList)) return []
  return goalList.map(goal => ({
    ...goal,
    parentKey: parent?.key || 'root',
    key: goal.relatedType + '-' + goal.relatedId + '-' + Math.random(), // relatedType和relatedId都是数字，用-相连
    simpleMode: false,
  }))
}

const updateGoalData = (goalNodeList: GoalNode[], parentKey: string, newGoal: Goal[]): GoalNode[] => {
  if (!isArray(goalNodeList)) return []
  return goalNodeList.map(node => {
    if (node.key === parentKey) return { ...node, children: toGoalNode(newGoal, node) }
    if (notEmptyArray(node.children)) return { ...node, children: updateGoalData(node.children, parentKey, newGoal) }
    return node
  })
}

const reFreshGoalData = (goalNodeList: GoalNode[], newGoalNode: GoalNode): GoalNode[] => {
  if (!isArray(goalNodeList)) return []
  return goalNodeList.map(node => {
    if (node.key === newGoalNode.key) return { ...node, ...newGoalNode }
    if (notEmptyArray(node.children)) return { ...node, children: reFreshGoalData(node.children, newGoalNode) }
    return node
  })
}

export default function GoalPage () {
  const { userInfo, currCompanyList } = store.useSession()
  const companyName = useMemo(() => currCompanyList?.find(item => item.companyId === userInfo.companyId)?.companyName, [userInfo, currCompanyList])

  const [goalData, setGoalData] = useState<GoalNode[]>([])

  const [filter, setFilter] = useObject<GoalFilter>(initFilter)
  const [loading, res, err] = useService(getGoal, filter)
  useShowError('获取目标管理失败', err)

  useEffect(() => {
    const list = res?.list
    if (!notEmptyArray(list)) {
      setGoalData([])
      return
    }

    // 先检查返回的对象是不是根节点，如果不是，要将当前公司设置为根节点，这是前端自己创建的根节点
    const goalNodeList: GoalNode[] = list[0].relatedType === GoalRelatedTypeEnum.COMAPNY ? toGoalNode(list) : [{
      simpleMode: true,
      parentKey: 'root',
      key: GoalRelatedTypeEnum.COMAPNY + '-' + userInfo.companyId + '-' + Math.random(), // 前端创建的根节点
      relatedType: GoalRelatedTypeEnum.COMAPNY,
      relatedId: userInfo.companyId,
      name: companyName,
      personalCustomerGoal: undefined,
      enterpriseCustomerGoal: undefined,
      belongMonth: filter.belongMonth, // 所属月份，格式为202110
      children: toGoalNode(list),
    }]
    setGoalData(goalNodeList)
  }, [res])

  const { authPointMap } = store.useGlobal()
  const canEdit = useMemo(() => {
    // 没有权限点不可以设置目标， 过去的日期和将来3个月之后的目标也不可以编辑
    if (!authPointMap?.mgtGoal?.result) return false
    const diffMonth = moment(filter.belongMonth)?.diff(initFilterValue.belongMonth, 'month')
    return diffMonth >= 0 && diffMonth <= 3
  }, [authPointMap, filter])

  const loadGoalTree = async ({ key, children }: EventDataNode<{children: any[]}>) => {
    if (notEmptyArray(children)) {
      return Promise.resolve()
    }

    const [relatedType, relatedId] = (key + '').split('-')
    const goalFilter: GoalFilter = {
      parentId: +relatedId,
      parentType: +relatedType,
      belongMonth: filter.belongMonth,
    }
    const [res, err] = await getGoal(goalFilter)
    if (err) {
      message.error(`获取目标管理失败: ${err?.message}`)
      return
    }
    setGoalData(origin => updateGoalData(origin, key + '', res?.list || []))
  }

  const toGoalTreeData = useCallback((nodeList: GoalNode[]): DataNode[] => {
    if (!isArray(nodeList)) return []
    return nodeList.map(node => ({
      key: node?.key,
      title: <GoalElement goalNode={node} canEdit={canEdit} afterUpdateGoal={newNode => updateGoal(newNode)} />,
      isLeaf: node.relatedType === GoalRelatedTypeEnum.SALESNAME,
      switcherIcon: node.relatedType === GoalRelatedTypeEnum.SALESNAME ? <UserOutlined /> : undefined,
      children: notEmptyArray(node.children) ? toGoalTreeData(node.children) : undefined,
    }))
  }, [])

  const updateGoal = useCallback((newNode: GoalNode) => {
    setGoalData(origin => reFreshGoalData(origin, newNode))
  }, [goalData])

  const goalTreeData = useMemo(() => toGoalTreeData(goalData), [goalData])

  return (
    <>
      <PageHeader title="目标管理" />
      <Card className="mt20">
        <CommonFilters
          className="custom-Label form-item-no-margin"
          initValue={initFilterValue}
          itemList={goalFilterItems}
          onChange={setFilter}
          formLayout={{
            labelCol: { span: 4 },
            wrapperCol: { span: 12 },
          }}
          rowProps={{ justify: 'start' }}
          colProps={{ flex: '0 0 400px' }}
          buttons={[]}
          // onSubmit={filter => setFilter({ ...filter, page: 1 })}
          // onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20" loading={loading}>
        {notEmptyArray(goalData) ? (
          <Tree
            showLine
            defaultExpandAll
            // defaultExpandParent
            loadData={loadGoalTree}
            treeData={goalTreeData}
          />
        ) : <Empty />}
      </Card>
    </>
  )
}
