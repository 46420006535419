/**
 * 公用筛选器2.0
 *
 * @author tylerzzheng
 */
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import './style'

import { RowProps } from 'antd/lib/grid'
import { ColProps } from 'antd/lib/grid/col'
import { FormProps } from 'antd/lib/form'
import { createForm, Form, isArray, isEmptyArray, isNullOrUndefined, isString, trimStr } from '@library'
import { FieldItem, renderFieldItem } from './configs'
import { FormValues } from '@tencent/meta-form'

export type FormLayout = Pick<FormProps, 'labelAlign' | 'labelCol' | 'layout' | 'wrapperCol'>
export type Buttons = 'submit' | 'reset'

export interface CommonFiltersProps {
  className?: string
  style?: React.CSSProperties
  cRef?: React.Ref<any>
  // 初始值
  initValue?: FormValues
  // 筛选框配置项
  itemList: FieldItem[]
  // 当改动筛选表单的时候
  onChange?: (value: FormValues) => void
  // 提交回调
  onSubmit?: (value: FormValues) => void
  // 点击回调按钮触发此事件，仅触发事件，value需要手动同步
  onReset?: () => void
  // antd的Row属性
  rowProps?: RowProps
  // antd的Col属性
  colProps?: ColProps
  // antd的Form的labelAlign,labelCol,layout等部分属性
  formLayout?: FormLayout
  // 可选buttons
  buttons?: Buttons[]
  // 当筛选器折叠时，仍然显示的item数量，默认6个。为0时不折叠全部显示。
  standstill?: number
  // 按钮与筛选框是否在同一行
  buttonInline?: boolean
}

const defaultFormLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const formStore = createForm<FormValues>({})
const { useValues, useValidate, Field, withForm } = formStore

const CF = function <T> (props: CommonFiltersProps) {
  const {
    className,
    style,
    cRef,
    initValue,
    itemList: allItemList = [],
    onChange,
    onSubmit = () => { }, // todo 类型
    onReset = () => { },
    rowProps,
    colProps,
    formLayout = defaultFormLayout,
    buttons = ['submit', 'reset'],
    standstill = 6,
    buttonInline = false,
  } = props

  const { validate } = useValidate()
  const { values, resetValues, setInitialValues, setValues } = useValues()

  const [isFolding, setFolding] = useState(true)
  const [triggerReset, setTriggerReset] = useState(true)
  const itemList = useMemo(() => {
    if (!isArray(allItemList)) return []
    if (standstill <= 0) return allItemList
    return isFolding ? _.take(allItemList, standstill) : allItemList
  }, [allItemList, isFolding, standstill])

  const showFoldingButton = useMemo(() => {
    // 当筛选框数目大于设置的数目时，的时候展示折叠/收起按钮
    if (!isArray(allItemList)) return false
    return allItemList.length > standstill && standstill > 0
  }, [allItemList, standstill])

  const handleValues = useCallback((values: FormValues) => {
    const obj = {}
    if (_.isEmpty(values) || _.isEmpty(allItemList)) return obj

    // todo 这里可以用parseParams重写一下
    for (const { fieldName, handleValue } of allItemList) {
      let val = values[fieldName]
      // 对string类型数据做处理
      val = isString(val) ? trimStr(val) : val
      if (handleValue) {
        const temp = handleValue(val, values)
        Object.assign(obj, temp)
      } else {
        // undefined必须显式的等于，否则filter不会置空 // 空数组上送后端会报错，后端不接受空数组，\摊手
        if (fieldName === 'focusProduct') {
          val?.length === 1 ? Object.assign(obj, { focusProduct: val?.[0] }) : Object.assign(obj, { focusProduct: undefined, focusFourthProduct: val?.[1] })
        } else {
          obj[fieldName] = (isNullOrUndefined(val) || isEmptyArray(val)) ? undefined : val
        }
      }
    }
    return obj
  }, [allItemList])

  // 每次表单变更时
  useEffect(() => {
    if (!onChange) return
    // todo 这里可以加一个防抖
    const handledValues = handleValues(values)
    onChange(handledValues)
  }, [values, handleValues, onChange])

  // 设定初始值
  useEffect(() => {
    initValue && setInitialValues(initValue)
  }, [])

  const handleSubmit = useCallback(async (values: FormValues) => {
    const validateRes = await validate()

    if (!validateRes.isPass) return
    const handledValues = handleValues(values)
    onSubmit(handledValues) // todo 提交前可以对比一下数据，如果一样的话就不提交了
  }, [validate, handleValues])

  const handleReset = useCallback(() => {
    resetValues(initValue || {})
    onReset && onReset()
  }, [onReset, resetValues, initValue])

  useImperativeHandle(cRef, () => ({
    // 把resetValues暴露出去, 调用此方法后会自动调用onSubmit
    resetValues: (values: FormValues = {}) => {
      console.log('resetValues to:', values)
      resetValues(values)
      handleSubmit(values)
    },
    setValues: (values: FormValues = {}) => {
      console.log('setValues to:', values)
      setValues(values)
    },
  }), [resetValues, handleValues, handleSubmit, setValues])

  return (
    <Form {...formLayout}>
      <Row {...rowProps} className={className} style={style}>
        {itemList.map((item, index) => {
          return (
            <Col flex="1 1 400px" style={{ maxWidth: '500px' }} {...colProps} key={index}>
              <Field
                field={item.fieldName}
                label={item.label}
                rule={item.rule}
                required={item.required}
              >
                {renderFieldItem(item, values, setValues, triggerReset)}
              </Field>
            </Col>
          )
        })}

        {showFoldingButton && isFolding && (
          <Col flex="0 0 400px" {...colProps}>
            <Button type="link" onClick={() => setFolding(false)}>展开更多筛选条件<DownOutlined /></Button>
          </Col>
        )}
        {showFoldingButton && !isFolding && (
          <Col flex="0 0 400px" {...colProps}>
            <Button type="link" onClick={() => setFolding(true)}>收起更多<UpOutlined /></Button>
          </Col>
        )}

        {!buttonInline && <div style={{ width: '100%' }} />}

        <Col flex="0 0 400px" {...colProps}>
          {buttons?.includes('submit') && <Button type="primary" onClick={() => handleSubmit(values)} htmlType="submit">查询</Button>}
          {buttons?.includes('reset')
          && (
            <Button
              className="ml10" onClick={() => {
                handleReset()
                setTriggerReset(!triggerReset)
              }}
            >
              重置
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export const CommonFilters = withForm(CF)
