/**
 * 业务相关映射,请确保下面定义的变量名都以 Map结尾
 * @author wzwwu
 */

// 跟进方式枚举、映射
import {
  ContactSourceTypeEnum,
  ISAgentClientEnum,
  ISAgentEnum,
  IsKaListEnum,
  LeadMatchTypeEnum,
  MajorSalesTypeEnum,
  sortTypeEnum,
  PayStatusEnum,
  TaskStatusEnum,
  CallEndReasonEnum,
  pageConfigEnum,
  CommunicateResultEnum,
  LeadRuleCondition,
  PayTypeEnum,
  // IntentionLevelEnum,
  ContactInfoStatusTypeEnum,
  BoundStatusEnum,
  BoundTypeEnum,
  ruleTypeEnum,
  OpportunityStatusEnum,
  OpportunityIsNewEnum,
  OpportunityWinRateEnum,
  LeadStatusEnum,
  ClientTypeEnum,
  CustomerStatusEnum,
  LinkedFlagEnum,
  DealTypeEnum,
  EstimateCustomerTypeEnum,
  StaffisAssign, CustomerTypeEnum,
  ApplyStatusEnum,
  customerCategoryTypeEnum,
  competitorTypeEunm,
  RuleStatus,
  ExeActionEnum,
  AppealStatusEnum, LeadExceedSlaEnum, LeadExceedPeriodEnum,
  RobotTaskStatusEnum,
  OpportunityTypeEnum,
  PhoneStatusEnum,
  CallRobotSourceEnum,
  AssignCheck,
} from '@types'

import {
  CarryOutOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  SyncOutlined,
} from '@ant-design/icons'

import React from 'react'
import { Badge } from 'antd'

// 即将被废弃，换成    ChannelMarkMap
// export const incomeTypeMap = {
//   [IncomeTypeEnum.NONE]: '中长尾',
//   [IncomeTypeEnum.KA]: 'KA',
//   [IncomeTypeEnum.AREA]: '区域',
//   [IncomeTypeEnum.CHANNEL]: '渠道',
//   [IncomeTypeEnum.LT]: '中长尾',
// }

// 收入来源定义(channelmark） http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000504761
export const ChannelMarkMap = {
  '6': '跨BG结算',
  '2': 'KA',
  '7': '区域直销',
  '1': '渠道',
  '0': '中长尾',
  '3': '渠道_KA复算',
  '8': '区域直销_渠道复算',
  '9': '区域直销_KA分成',
  '10': '中长尾_渠道分成',
  '11': '区域直销_KA分成_渠道复算',
  '12': '区域直销(国际)_区域直销(国内)复算',
  '13': '区域直销(国际)_KA复算',
  '14': 'KA 渠道复算 区域直销(国际)复算',
  '15': '区域 渠道复算 区域直销(国际)复算',
}

export const authStateMap = {
  '-1': '账号注销',
  '0': '未注册未认证',
  '1': '已注册未认证',
  '3': '认证通过',
}

export const majorSalesTypeMap = {
  [MajorSalesTypeEnum.NONE]: '无',
  [MajorSalesTypeEnum.KA]: '行业直销(KA)',
  [MajorSalesTypeEnum.AREA]: '区域直销',
  [MajorSalesTypeEnum.CHANNEL]: '渠道销售',
  [MajorSalesTypeEnum.BD]: '创投BD',
}

export const IsAgentMap = {
  [ISAgentEnum.YES]: '是',
  [ISAgentEnum.NO]: '否',
}

export const IsAssignMap = {
  [StaffisAssign.YES]: '是',
  [StaffisAssign.NO]: '否',
}

export const IsAgentClientMap = {
  [ISAgentClientEnum.YES]: '是',
  [ISAgentClientEnum.NO]: '否',
}

export const IsKaListMap = {
  [IsKaListEnum.YES]: '是',
  [IsKaListEnum.NO]: '否',
}

export const communicateResultMap = {
  [CommunicateResultEnum.INVALID]: '无效',
  [CommunicateResultEnum.VALID]: '有效',
}

// 线索分配规则状态映射
export const leadMatchTypeMap = [
  { value: LeadMatchTypeEnum.RULE_MATCH_LEAD, text: '共享' },
  { value: LeadMatchTypeEnum.LEAD_MATCH_RULE, text: '独占' },
]

// 线索分配规则状态映射
export const leadRuleStatusMap = {
  [RuleStatus.ON]: '启用',
  [RuleStatus.OFF]: '停用',
}

export const leadAssignCheckMap = {
  [AssignCheck.ON]: '开启',
  [AssignCheck.OFF]: '关闭',
}

export const matchTypeMap = {
  equal: '等于',
  notEqual: '不等于',
  moreThan: '大于',
  lessThan: '小于',
  lessThanOrEqual: '小于等于',
  moreThanOrEqual: '大于等于',
  in: '包含',
  notIn: '不包含',
}

// 线索规则筛选条件
export const leadRuleConditionMap: {[key: string]: LeadRuleCondition} = {
  'lead.status': {
    label: '线索状态',
    matchType: ['equal', 'notEqual'],
  },
  'lead.sourceTagIds': {
    label: '客户来源',
    matchType: ['equal', 'notEqual'],
  },
  'lead.touchStatus': {
    label: '触达状态',
    matchType: ['equal', 'notEqual'],
  },
  'lead.leadTags': {
    label: '原线索标签',
    matchType: ['in', 'notIn'],
  },
  'lead.tagIds': {
    label: '公共标签',
    matchType: ['equal', 'notEqual'],
  },
  'lead.primaryIndustryIncomeId': {
    label: '行业',
    matchType: ['equal'],
  },
  'lead.payType': {
    label: '付费类型',
    matchType: ['equal', 'notEqual'],
  },
  'lead.intentionLevel': {
    label: '原线索一级意向',
    matchType: ['equal', 'notEqual'],
  },
  'lead.batchId': {
    label: '批次ID',
    matchType: ['equal', 'notEqual', 'in', 'notIn'],
  },
  'lead.originalBatchId': {
    label: '原始批次ID',
    matchType: ['equal', 'notEqual', 'in', 'notIn'],
  },
  'lead.provinceCode': {
    label: '省份',
    matchType: ['in', 'notIn'],
  },
  'lead.cityCode': {
    label: '城市',
    matchType: ['in', 'notIn'],
  },
  'customer.customerType': {
    label: '客户类型',
    matchType: ['equal', 'notEqual'],
  },
  'customer.relatedStatus': {
    label: '客户状态',
    matchType: ['equal'],
  },
  'customer.lastValidFollowTime': {
    label: '上次有效跟进日期',
    matchType: ['equal', 'moreThan', 'lessThan', 'lessThanOrEqual', 'moreThanOrEqual'],
    tips: '该筛选条件，对于没有跟进记录的客户，视为上次有效跟进日期为: 1970-01-01',
  },
  'customer.lastFollowTime': {
    label: '上次跟进日期',
    matchType: ['equal', 'moreThan', 'lessThan', 'lessThanOrEqual', 'moreThanOrEqual'],
    tips: '该筛选条件，对于没有跟进记录的客户，视为上次跟进日期为: 1971-01-01',
  },
  'customer.authState': {
    label: '认证状态',
    matchType: ['equal', 'notEqual'],
  },
  'customer.followerCompanyId': {
    label: '当前所属公司',
    matchType: ['equal'],
  },
  // 'customer.firstPaytime': { // 暂时隐藏
  //   label: '首次成单日期',
  //   matchType: ['equal', 'moreThan', 'lessThan', 'lessThanOrEqual', 'moreThanOrEqual'],
  // },
  'customer.followerGroup': {
    label: '当前所在组',
    matchType: ['equal', 'notEqual'],
  },
  'customer.follower': {
    label: '当前跟进人',
    matchType: ['equal'],
  },
}

// 线索规则筛选客户类型
export const leadRuleCustomerTypeMap = [
  { value: '0', text: '个人' },
  { value: '1', text: '企业' },
  { value: '2', text: '政府' },
  { value: '3', text: '组织' },
]

// 线索规则筛选认证状态
export const leadRuleAuthStateMap = [
  { value: '-1', text: '账号注销' },
  { value: '0', text: '未注册未认证' },
  { value: '1', text: '已注册未认证' },
  { value: '3', text: '认证通过' },
]

// 线索付费类型映射
export const payTypeMap = {
  [PayTypeEnum.UNPAID_UNUSED]: '未付费未使用',
  [PayTypeEnum.UNPAID_USED]: '未付费已使用',
  [PayTypeEnum.PAID]: '已付费',
}

// 线索是否超SLA
export const exceedSlaMap = {
  [LeadExceedSlaEnum.NO]: '否',
  [LeadExceedSlaEnum.YES]: '是',
}

// 线索是否失效
export const exceedPeriodMap = {
  [LeadExceedPeriodEnum.NO]: '否',
  [LeadExceedPeriodEnum.YES]: '是',
}

// 客户状态类型映射
export const linkedFlagMap = {
  [LinkedFlagEnum.LINKED]: '已关联',
  [LinkedFlagEnum.UNDER_INVESTIGATED]: '考察中',
  [LinkedFlagEnum.READY_ASSIGNED]: '待分配',
}

// 线索付费类型映射
// export const intentionLevelMap = {
//   [IntentionLevelEnum.H]: '高',
//   [IntentionLevelEnum.M]: '中',
//   [IntentionLevelEnum.L]: '低',
// }

export const leadStatusMap = {
  [LeadStatusEnum.UNHANDLE]: <><ClockCircleOutlined className="mr5" />未派发</>,
  [LeadStatusEnum.ASSIGNED]: <><CarryOutOutlined className="text-primary mr5" />已派发</>,
  [LeadStatusEnum.DISCARDED]: <><DeleteOutlined className="mr5" />已废弃</>,
  [LeadStatusEnum.FOLLOWED]: <><CheckCircleOutlined className="text-success mr5" />已跟进</>,
}

export const appealStatusMap = {
  [AppealStatusEnum.NOAPPEAL]: <><Badge status="default" />未申诉</>,
  [AppealStatusEnum.APPELING]: <><Badge status="processing" />申诉中</>,
  [AppealStatusEnum.APPEALSUCCESS]: <><Badge status="success" />申诉成功</>,
  [AppealStatusEnum.APPEALFAILED]: <><Badge status="error" />申诉失败</>,
}

// 订单状态
export const statusMap = {
  '1': '未支付',
  '2': '已支付',
  '3': '发货中',
  '4': '发货成功',
  '5': '发货失败',
  '6': '已退款',
  '7': '已取消',
  '8': '已过期',
  '9': '不合法订单',
  // '10': '',  // 已废弃最后一个订单是15年的
  '11': '代理支付拒绝',
  '12': '支付中',
  '13': '退款中',
  '30': '待用户确认完成',
  '100': '已删除',
}

// 订单类型
export const actionMap = {
  'purchase': '新购',
  'renew': '续费',
  'upgrade': '升配',
  'downgrade': '降配',
  'refund': '退货退款',
  'modifyNetworkMode': '调整带宽模式',
  'modifyNetworkSize': '调整带宽大小',
}

// 订单类型 - orders看板接口用到的
export const dealTypeMap = {
  [DealTypeEnum.UNKNOWN]: '未知',
  [DealTypeEnum.NEW_BUY]: '新购',
  [DealTypeEnum.CONTINUE_BUY]: '续费',
  [DealTypeEnum.UPGRADE]: '变配',
  [DealTypeEnum.REFOUND]: '退费',
}

/**
 * 排序。从antd的值类型到接口定义的值映射
 */
export const sortMap = {
  'descend': sortTypeEnum.DESC,
  'ascend': sortTypeEnum.ASC,
}
/**
 * 排序。从接口定义的值类型到antd的值映射
 */
export const antdSortMap = {
  [sortTypeEnum.DESC]: 'descend',
  [sortTypeEnum.ASC]: 'ascend',
} as const

export const workOrderStatusMap = [
  { id: 21, name: 'PENDING', value: '待处理' },
  { id: 22, name: 'IN_HAND', value: '处理中' },
  { id: 3, name: 'CLOSED', value: '已结单' },
  { id: 4, name: 'TO_BE_ADDED_BY_CUSTOMER', value: '待补充' },
  { id: 23, name: 'CLOSE_APPLICATION', value: '申请结单' },
  { id: 5, name: 'CLOSE_CONFIRMATION_PENDING', value: '待确认结单' },
  { id: 10, name: 'CANCELLED', value: '已撤销' },
  { id: 11, name: 'DELETED', value: '已删除' },
  { id: 24, name: 'ADD_INFO_APPLICATION', value: '申请补充或待复现' },
  { id: 25, name: 'TO_CONFIRM_RESTORE', value: '待确认业务恢复' },
  { id: 26, name: 'RESTORED', value: '已恢复分析根因' },
]

/**
 * 线索,变更类型
 */
export const changeTypeMap = {
  0: '自动分配',
  1: '人工分配',
  2: '释放',
  3: '废弃',
  4: '线索进入当前模块',
}

/**
 * 客户,变更类型
 */
export const flowTypeMap = {
  0: '自动分配',
  1: '人工分配',
  2: '释放',
  3: '废弃',
  4: '客户进入当前模块',
  5: 'uin进入当前模块',
  6: '客户合并',
  7: '客户转出',
  8: 'CID变更',
  9: '考察转关联',
  10: '客户回流',
  11: '兜底分配',
}

export const customerTypeMap = {
  [CustomerTypeEnum.PERSON]: '个人',
  [CustomerTypeEnum.COMPANY]: '企业',
  [CustomerTypeEnum.GOVERMENT]: '政府',
  [CustomerTypeEnum.ORGANIZATION]: '组织',
}

export const assignTypeMap = {
  1: '手动',
  2: '自动',
}

export const taskTypeMap = {
  1: '新任务',
  // 2: '复呼任务',
}

export const assignStatusMap = {
  0: '待分配',
  1: '已分配',
}

export const contactStatusMap = {
  [ContactInfoStatusTypeEnum.NOT_VERIFIED]: '未验证',
  [ContactInfoStatusTypeEnum.VALID]: '有效',
  [ContactInfoStatusTypeEnum.INVALID]: '无效',
  [ContactInfoStatusTypeEnum.RECOMMENDED]: '优先推荐',
  [ContactInfoStatusTypeEnum.BLACKLIST]: '拒绝联系',
}

export const ContactSourceTypeMap = {
  [ContactSourceTypeEnum.BY_LEAD]: '线索带入',
  [ContactSourceTypeEnum.BY_MANUAL]: '自行录入',
}

export const CallEndReasonMap = {
  [CallEndReasonEnum.OK]: '接听成功',
  [CallEndReasonEnum.REJECT]: '拒接',
  [CallEndReasonEnum.NO_RESPONSE]: '无人接听',
  [CallEndReasonEnum.SHUTDOWN]: '关机',
  [CallEndReasonEnum.NO_SERVICE]: '停机',
  [CallEndReasonEnum.BUSY]: '正在通话',
  [CallEndReasonEnum.CAN_NOT_REACH]: '暂时无法接通',
  [CallEndReasonEnum.EMPTY_NUMBER]: '空号',
  [CallEndReasonEnum.NOT_LOGIN]: '座席sdk未登录',
  [CallEndReasonEnum.WAITING]: '呼叫等待',
  [CallEndReasonEnum.HANGUP_AFTER_RING]: '主叫挂断（被叫振铃后）',
  [CallEndReasonEnum.HANGUP_BEFORE_RING]: '主叫取消（被叫振铃前）',
}

export const transferTypeMap = {
  0: '主动转出',
  1: '被动转出',
}

// 客户是否被转出
export const customerStatusMap = {
  [CustomerStatusEnum.NORMAL]: '未转出',
  [CustomerStatusEnum.TRANFSFER_OUT]: '已转出',
}
// 客户付费状态
export const payStatusMap = {
  [PayStatusEnum.PAID]: '已付费',
  [PayStatusEnum.UNPAID]: '未付费',
}

export const taskStatusMap = {
  [TaskStatusEnum.HANDLED]: '已处理',
  [TaskStatusEnum.UNHANDLED]: '未处理',
  [TaskStatusEnum.CLOSED]: '已关闭',
}

export const saasStatusMap = {
  1: '待确认',
  2: '确认中',
  '-1': '已取消',
  9: '下单失败',
  10: '已下单',
}

// 优惠券状态
export const voucherStatusMap = {
  0: '未发放',
  1: '待使用',
  2: '已冻结',
  3: '已使用',
  4: '已过期',
  5: 'ready to activity',
  6: '已作废',
}

// 优惠券详情审批状态
export const voucherApprovalStatusMap = {
  0: '待审批',
  1: '审批中',
  2: '审批驳回',
  3: '审批通过',
}

// 优惠券详情执行状态
export const voucherPolicyStatusMap = {
  0: '未执行',
  1: '执行中',
  2: '已执行',
}

// 短信模板占位符
export const signMap = {
  name: '姓名',
  qq: 'QQ号',
  phone: '联系方式',
  weworkShortChain: '企微短链',
  bindingURL: '专属链接',
}

// 客户满意度
export const satisfactionResultMap = {
  0: '未评价',
  1: '非常不满意',
  2: '不太满意',
  3: '一般',
  4: '比较满意',
  5: '非常满意',
}

// 自动处理规则筛选-规则分类
export const ruleTypeMap = {
  [ruleTypeEnum.AUTO]: '系统自动分配',
  [ruleTypeEnum.MANUAl]: '手动分配',
}

// 自动处理规则筛选-执行操作
export const exeActionMap = {
  [ExeActionEnum.CLAIM_LEAD]: '派发线索',
  [ExeActionEnum.ASSIGN_CUSTOMER]: '分配客户',
  [ExeActionEnum.RECOVER_CUSTOMER]: '回收客户',
}

// 是否有通话时常
// export const callStatusMap = {
//   0: '无记录',
//   1: '有记录',
// }

// 任务类型
export const missionTypeMap = {
  1: '代理商绑定',
  2: '批量添加线索',
}

// 本月应收预估
export const shouldIncomePreMap = {
  '499.99': '小于500', // 之所以写成sting，是因为
  500: '大于等于500',
}

// 认证状态
export const authStatusMap = {
  0: '未注册未认证',
  1: '已注册未认证',
  3: '认证通过',
}

export const accountTypeMap = {
  0: '普通账号',
  1: '虚拟账号',
}

//
export const shouldIncomeLevelMap = {
  T0: 'T0',
  T1: 'T1',
  'T2.1': 'T2.1',
  'T2.2': 'T2.2',
  T3: 'T3',
  T4: 'T4',
  T5: 'T5',
  T6: 'T6',
  T7: 'T7',
  T8: 'T8',
  T9: 'T9',
  T10: 'T10',
  T11: 'T11',
}

// src/components/CommonFilters/configs.tsx中使用
export const preShouldIncomeLevelMap = shouldIncomeLevelMap
export const preShouldIncomeLevelDayMap = shouldIncomeLevelMap
export const currentShouldIncomeLevelDayMap = shouldIncomeLevelMap

// IT规模映射
export const itScaleMap = {
  1: '小于10w',
  2: '10-50w',
  3: '50-100w',
  4: '100w以上',
}

// 优惠券适用场景
export const voucherActionlMap = {
  purchase: '新购',
  modify: '变配',
  renew: '续费',
  settleAccount: '按量付费',
}

// 客户类别
export const customerCategoryTypeMap = {
  [customerCategoryTypeEnum.UNKNOWN]: '未知',
  [customerCategoryTypeEnum.EU]: 'EU',
  [customerCategoryTypeEnum.ISV]: 'ISV',
}

// 友商
export const competitorTypeMap = {
  [competitorTypeEunm.OTHER]: '其他',
  [competitorTypeEunm.ALI]: '阿里',
  [competitorTypeEunm.HUWEI]: '华为',
}

// 销售机会 机会类型
export const opportunityTypeMap = {
  [OpportunityTypeEnum.NEW_PURCHASE]: '新购机会',
  [OpportunityTypeEnum.RE_PURCHASE]: '复购机会',
  [OpportunityTypeEnum.COUNTINE_BUY]: '续费机会',
  [OpportunityTypeEnum.SMB]: 'SMB合作机会',
  [OpportunityTypeEnum.OTHER]: '其它',
  [OpportunityTypeEnum.HIGH_POOTENTIAL]: '高潜专项',
}

// 销售机会 客户预估类型
export const estimateCustomerTypeMap = {
  [EstimateCustomerTypeEnum.COMPANY]: '企业',
  [EstimateCustomerTypeEnum.PERSON]: '个人',
  [EstimateCustomerTypeEnum.LIKE_PERSON]: '伪个人',
  [EstimateCustomerTypeEnum.UNKNOWN]: '未知',
}

// 销售机会 机会状态
export const opportunityStatusMap = {
  [OpportunityStatusEnum.PROCESSING]: <><SyncOutlined className="text-primary mr5" />进行中</>,
  [OpportunityStatusEnum.SUCCEED]: <><CheckCircleOutlined className="text-success mr5" />已赢单</>,
  [OpportunityStatusEnum.CLOSED]: <><CloseCircleOutlined className="mr5" />已关闭</>,
}

// 销售机会 是否新客
export const opportunityIsNewMap = {
  [OpportunityIsNewEnum.NO]: '非新客',
  [OpportunityIsNewEnum.YES]: '新客',

}
// 销售机会 赢单率
export const opportunityWinRateMap = {
  [OpportunityWinRateEnum.ZERO]: '0%',
  [OpportunityWinRateEnum.QUARTER]: '25%',
  [OpportunityWinRateEnum.HALF]: '50%',
  [OpportunityWinRateEnum.THREE_QUARTER]: '75%',
  [OpportunityWinRateEnum.NINETY_PERCENT]: '90%',
  [OpportunityWinRateEnum.HUNDRED_PERCENT]: '100%',
}

// 额外表单
export const pageConfigMap = {
  [pageConfigEnum.EXTRAFORM]: '留资',
  [pageConfigEnum.DEFALUT]: '跟进',
}

export const operationLogActionMap = {
  get: '查看',
  add: '添加',
  update: '更新',
  delete: '删除',
  export: '导出',
  send: '发送',
  assign: '分配',
  transfer: '流转',
  call: '外呼',
  merge: '合并',
  run: '执行',
}
// 解绑状态
export const unboundStatusMap = {
  [BoundStatusEnum.WAIT]: <><ClockCircleOutlined className="text-primary mr5" />待人工解绑</>,
  [BoundStatusEnum.SUCCESS]: <><CheckCircleOutlined className="text-success mr5" />解绑成功</>,
  [BoundStatusEnum.ERROR]: <><CloseCircleOutlined className="text-danger mr5" />解绑失败</>,
}

// 解绑方式
export const unboundTypeMap = {
  [BoundTypeEnum.AUTO]: '自动解绑',
  [BoundTypeEnum.MANUAL]: '人工解绑',
}

// 代客类型
export const clientTypeMap = {
  [ClientTypeEnum.NEW]: '新拓',
  [ClientTypeEnum.OLD]: '存量-已关联',
  [ClientTypeEnum.OLD_NEW_CHECKING]: '存量-新关联(考核中)',
  [ClientTypeEnum.OLD_NEW_NOT_PASS]: '存量-新关联(未达标)',
  [ClientTypeEnum.ASSIGN]: '指派',
  [ClientTypeEnum.DIRECT]: '直销',
  [ClientTypeEnum.DIRECT_NEW_OPP]: '直销(新商机)',
}

// 权限申请状态
export const salesApprovalApplyMap = {
  [ApplyStatusEnum.APPROVAL]: '审批中',
  [ApplyStatusEnum.ACCEPT]: '已通过',
  [ApplyStatusEnum.REJECT]: '已驳回',
}

// 机器人名单状态
export const robotTaskStatusMap = {
  [RobotTaskStatusEnum.FAIL]: '创建失败',
  [RobotTaskStatusEnum.RUNNING]: '筛选中',
  [RobotTaskStatusEnum.SUCCEED]: '创建成功',
  [RobotTaskStatusEnum.WAITING]: '待筛选',
  [RobotTaskStatusEnum.DONE]: '拨打完成',
}

// 电话状态
export const phoneStatusMap = {
  [PhoneStatusEnum.DEFAULT]: '默认',
  [PhoneStatusEnum.STOP]: '停机',
  [PhoneStatusEnum.EMPTY]: '空号',
}

// 私域关联-发送状态
export const privateSendStatusMap = {
  0: '未发送',
  1: '发送成功',
  2: '发送失败',
}

// 机器人外呼入口
export const sourceTypeMap = {
  [CallRobotSourceEnum.CUSTOMER_LIST]: '客户资源池',
  [CallRobotSourceEnum.LEAD_LIST]: '线索公海池',
  [CallRobotSourceEnum.LEAD_LIST_SENT]: '已派发线索列表',
  [CallRobotSourceEnum.CALL_RECORD]: '批量上传',
}
