import React, { useMemo } from 'react'
import { Select, Table, DatePicker, Card, Alert } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { OrderRecordItem } from '@types'
import { useService, useShowError } from '@hooks'
import { Link } from 'react-router-dom'
import { getGoodsIdDesc, getBillingOrderList, exportBillingOrderList } from '@services/customer'
import { getTableXwidth } from '@configs/table'
import { CommonFilters, PageHeader, TableDownload, UserAvatar, CommonSelector } from '@components'
import { useTableService } from '@hooks/useTableService'
import { formatTime, trimStr } from '@library'
import { groupId } from '../../../components/CommonSelector/configs'
import CompanySelector from '../../../components/CommonSelector/CompanySelector'

const TIME_ENUM = { y: '年', m: '月', d: '日', h: '时', M: '分', s: '秒', p: '套餐包' }

const { RangePicker } = DatePicker
const initFilter: any = {
  page: 1,
  pageSize: 10,
}

export default function OrderRecord () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getBillingOrderList, initFilter, '获取订单明细失败')
  const [, goodsNameres, goodsNameerr] = useService(getGoodsIdDesc)
  useShowError('获取产品名称失败', goodsNameerr)

  const columns: ColumnProps<OrderRecordItem>[] = useMemo(() => [
    {
      title: '订单号',
      dataIndex: 'dealName',
      width: 100,
    },
    {
      title: '购买产品',
      dataIndex: 'goodsCategoryId',
      width: 200,
      render: goodsCategoryId => goodsNameres?.idDesc && (goodsNameres?.idDesc?.[goodsCategoryId] || ''),
    },
    {
      title: 'UIN',
      dataIndex: 'uin',
      width: 100,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 220,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    {
      title: 'cid',
      dataIndex: 'cid',
      width: 100,
    },
    {
      title: '订单类型',
      dataIndex: 'actionCh',
      width: 120,
      // render: action => actionMap[action] || '',
    },
    {
      title: '是否自动续费',
      dataIndex: 'isAutoRenew',
      width: 140,
      // render: (_, record: any) => {
      //   const detail = JSON.parse(record?.goodsDetail)
      //   if (String(detail?.autoRenewFlag) === '0') return '否'
      //   if (String(detail?.autoRenewFlag) === '1') return '是'
      //   return ''
      // },
    },
    {
      title: '支付时间',
      dataIndex: 'completionTime',
      width: 150,
      render: payEndTime => payEndTime === '0000-00-00 00:00:00' ? '' : payEndTime,
    },
    {
      title: '订单时长',
      dataIndex: 'timeSpan',
      width: 90,
      render: (_, record: any) => {
        if (record?.timeSpan && record?.timeUnit) {
          return record?.timeSpan + TIME_ENUM[record?.timeUnit]
        }
        return ''
      },
    },
    {
      title: '有效期',
      dataIndex: 'orderValidEndTime',
      width: 130,
    },
    {
      title: '刊例价(元)',
      dataIndex: 'totalCost',
      width: 160,
      // render: (_, record: any) => {
      //   const detail = JSON.parse(record?.goodsPrice)
      //   return detail?.totalCost && (detail?.totalCost / 100).toFixed(2)
      // },
    },
    {
      title: '折扣价(元)',
      dataIndex: 'realTotalCost',
      width: 160,
      // render: (_, record: any) => {
      //   // const detail = JSON.parse(record?.goodsPrice)
      //   return record?.realTotalCost && (record?.realTotalCost / 100).toFixed(2)
      // },
    },
    {
      title: '代金券抵扣(元)',
      dataIndex: 'voucherDecline',
      width: 150,
      // render: (value) => value && (value / 100).toFixed(2),
    },
    {
      title: '折扣价-代金券(元)',
      dataIndex: 'voucherId',
      width: 180,
      render: (_, record: any) => {
        // const detail = JSON.parse(record?.goodsPrice)
        return record?.realTotalCost && ((record?.realTotalCost - record?.voucherDecline)).toFixed(2)
      },
    },
    {
      title: '归属坐席',
      dataIndex: 'salesName',
      width: 140,
      render: value => <UserAvatar salesName={value} />,
    },
    {
      title: '群组',
      dataIndex: 'groupCh',
      width: 180,
    },
    {
      title: '公司',
      dataIndex: 'companyName',
      width: 180,
    },
  ], [goodsNameres])

  // const disabledDate = (current) => {
  //   const afterToday = current > moment()
  //   if (!dates) {
  //     return !!afterToday
  //   }
  //   const tooLate = dates?.[0] && current.diff(dates?.[0], 'days') > 365
  //   const tooEarly = dates?.[1] && dates?.[1].diff(current, 'days') > 365
  //   return !!tooEarly || !!tooLate || !!afterToday
  // }

  // const onOpenChange = (open: boolean) => {
  //   if (open) {
  //     setCreateDates([null, null])
  //   } else {
  //     setCreateDates(null)
  //   }
  // }

  const filterItems: any = useMemo(() => [
    { fieldName: 'dealName', label: '订单号', type: 'input' },
    { fieldName: 'uin', label: 'UIN', type: 'multiTags' },
    // { fieldName: 'status', label: '订单状态', type: 'maps' },
    // { fieldName: 'action', label: '订单类型', type: 'maps' },
    { fieldName: 'salesName', label: '归属坐席', type: 'staffSelect' },
    {
      fieldName: 'payEndTime',
      label: '支付时间',
      width: 180,
      handleValue: val => {
        return {
          completionTimeBegin: formatTime(val?.[0], 'start'),
          completionTimeEnd: formatTime(val?.[1], 'end'),
        }
      },
      render: () => (
      // render: (_, setValues) => (
        <RangePicker
          // disabledDate={disabledDate}
          // onCalendarChange={val => {
          //   if (Math.abs(val?.[0].diff(val?.[1], 'days')) > 365) {
          //     // 修复此场景下antd组件的控制范围缺陷
          //     setTimeout(() => setValues({ createTime: [val?.[0], null] }), 100)
          //     setCreateDates([val?.[0], null])
          //     message.info('查询范围不可超过一年，请您重新选择')
          //   } else setCreateDates(val)
          // }}
          // onOpenChange={onOpenChange}
          onBlur={() => console.log('blur has been triggered')}
        />
      ),
    },
    {
      fieldName: 'goodsCategoryId',
      label: '购买产品',
      render: () => (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={goodsNameres?.idDesc ? Object.keys(goodsNameres?.idDesc)?.filter(o => o !== '0')?.map(o => o && ({
            label: goodsNameres?.idDesc?.[o],
            value: o,
          })) : []}
        />
      ),
    },
    { fieldName: 'companyId', label: '公司', render: () => <CompanySelector /> },
    { fieldName: 'groupId', label: '群组', render: () => <CommonSelector {...groupId} /> },
  // ], [uin, dates])
  ], [goodsNameres])

  return (
    <>
      <PageHeader title="订单明细" />
      <Alert message="当前页面订单范围仅限符合专属链接匹配规则的预付费订单，仅作为参考，不是最终业绩！" type="error" style={{ marginTop: 15 }} />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          colProps={{ flex: '1 0 300px' }}
          // initValue={initFilterValue}
          itemList={filterItems}
          standstill={9}
          onSubmit={filter => {
            console.log('===================onSubmit', filter)
            setFilter({ ...filter, page: 1 })
          }}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <TableDownload className="ml20" style={{ marginBottom: 10 }} exportMethod={exportBillingOrderList} filter={filter} />
        <Table
          // className="mt20"
          size="small"
          loading={loading}
          pagination={pagination}
          rowKey={rowKey}
          dataSource={list}
          columns={columns}
          scroll={{ x: getTableXwidth(columns) + 200 }}
        />
      </Card>
    </>
  )
}
