/**
 * $ 筛选器的配置项
 * @author tylerzzheng
 */

import React from 'react'
import * as maps from '@configs/map'
import { MinusOutlined, StopOutlined } from '@ant-design/icons'
import { DatePicker, Input, InputNumber, Select, Row, Col } from 'antd'
import { CommonCascador, CommonSelector, CommonTreeSelector, TreeDataSimple } from '@components'
import {
  formatTime,
  handleNumber,
  isEmptyArray,
  isFunction,
  isNullOrUndefined,
  isNumber,
  notEmptyArray,
} from '@library'
import {
  batchId,
  extraFormId, groupId, opportunityProducts,
  privateTag,
  publicTag,
  ruleId, ruleTypesNoSession,
  salesName,
  touchStatus, followType, opportunityScene,
  customersName,
  // registerChannelName2,
  // registerChannelName3,
  provinceCode, leadTags, taskName,
  ListCallRollTitle,
  robotTaskList,
  ListCallTag,
  opportunityType,
  opportunityProductsAll,
  saasProducts,
  getSalesChannel,
  groupName,
} from '../CommonSelector/configs'
import RegisterChannelNameSelect from './RegisterChannelNameSelect'
import { leadStatusMap, customerCategoryTypeMap, competitorTypeMap, robotTaskStatusMap, CallEndReasonMap, privateSendStatusMap } from '@configs/map'
import { GoalRelatedTypeEnum, LeadStatusEnum } from '@types'
import moment from 'moment'
import { FieldProps, FormRule, FormValues } from '@tencent/meta-form'
import CompanySelector from '../CommonSelector/CompanySelector'
import { customerIntention, uinIncomeIndustry } from '../CommonTreeSelector/configs'
import { area, industryProducts, leadIntentionLevel, sourceTag } from '../CommonCascador/configs'

const { RangePicker, MonthPicker } = DatePicker
const { Option } = Select

export type ItemType =
  | 'rangePicker'
  | 'monthPicker'
  | 'datePicker'
  | 'staffSelect'
  | 'staffSelectMulti'
  | 'input'
  | 'inputNumber'
  | 'groupId'
  | 'groupName'
  | 'extraFormId'
  | 'privateTag'
  | 'publicTag'
  | 'opportunityProducts'
  | 'ruleType'
  | 'batchId'
  | 'ruleId'
  | 'sourceTag'
  | 'maps'
  | 'mapsMulti'
  | 'intention'
  | 'touchStatus'
  | 'multiTags'
  | 'company'
  | 'inputNumberRange'
  | 'staffSelectNull'
  | 'mapsLeadStatus'
  | 'followType'
  | 'customerNameSelect'
  | 'cidSalesChannelSelect'
  | 'uinSalesChannelSelect'
  | 'registerChannelName2Select'
  | 'registerChannelName3Select'
  | 'uinIncomeIndustrySelect'
  | 'competingFirm'
  | 'competingExpirationTimeRange'
  | 'competingExpirationProducts'
  | 'opportunityProductsCascader'
export interface FieldItem {
  // 字段名称 这里指的是筛选框内部的form的字段名称，没有指定handleValue时，对外也是这个名称，指定handleValue将以指定的为准
  fieldName: string
  // 显示名
  label: string
  // 渲染选项
  render?: (values: FormValues, setValues: any) => FieldProps<any>['children']
  // 筛选框类型，当有render的时候，会忽略type
  type?: ItemType
  // async-validator 的校验规则
  rule?: FormRule | FormRule[]
  required?: boolean
  // submit或onchange之前，对该val的处理规则, val有可能是undefined，会对每个FieldItem遍历
  handleValue?: (val: any, values?: FormValues) => FormValues
}

// 无示意，只是一个指示器。 用于在switch/case和handValue的时候保持统一
const IDENTIFIER_STRING = 'identifier'

/**
 * 筛选框渲染器
 * @param item 渲染的item
 * @param formValue （可选）渲染时表单的值，有的筛选项需要根据表单值来渲染
 */
export const renderFieldItem = (item: FieldItem, formValue?: FormValues, setValues?: any, triggerReset?: boolean) => {
  const { type, render } = item
  let ele: FieldProps<any>['children']

  if (isFunction(render)) {
    return render(formValue, setValues)
  }

  switch (type) {
    case 'rangePicker': ele = (<RangePicker />); break
    case 'monthPicker': ele = (<MonthPicker />); break
    case 'datePicker': ele = (<DatePicker />); break
    case 'staffSelect': ele = (<CommonSelector {...salesName} />); break
    case 'staffSelectMulti': ele = (<CommonSelector {...salesName} mode="multiple" />); break
    case 'staffSelectNull': ele = (
      <CommonSelector
        {...salesName}
        mode="multiple"
        extraList={[{ value: IDENTIFIER_STRING, children: <><StopOutlined className="mr5" />无{item.label}</>, title: `无${item.label}`, label: `无${item.label}` }]} // 这里『无』的value是IDENTIFIER_STRING而不是后端约定的""的原因是，select组件无法反选中value为""的value
      />)
      break
    case 'registerChannelName2Select': ele = (<RegisterChannelNameSelect fieldName="registerChannelName2" setValues={setValues} triggerReset={triggerReset} />); break
    case 'registerChannelName3Select': ele = (<RegisterChannelNameSelect fieldName="registerChannelName3" setValues={setValues} triggerReset={triggerReset} />); break
    // case 'registerChannelName3Select': ele = (<CommonSelector {...registerChannelName3} />); break
    case 'cidSalesChannelSelect': ele = (<CommonSelector {...getSalesChannel} />); break
    case 'uinSalesChannelSelect': ele = (<CommonSelector {...getSalesChannel} />); break
    case 'customerNameSelect': ele = (<CommonSelector {...customersName} />); break
    // input这里之所以要写onChange(e.target.value || undefined)， 是因为空字符串上送到后端会报错。你没看错，服了
    case 'input': ele = ({ value, onChange }) => <Input value={value} onChange={e => onChange(e.target.value || undefined)} allowClear />; break
    case 'inputNumber': ele = (<InputNumber />); break
    case 'inputNumberRange': ele = ({ value, onChange }) => {
      const from = handleNumber(_.get(value, '[0]'))
      const to = handleNumber(_.get(value, '[1]'))
      return (
        <Row justify="space-between" align="middle">
          <Col span={10}>
            <InputNumber value={from} onChange={val => onChange([val, to])} />
          </Col>
          <Col span={1}>
            <MinusOutlined />
          </Col>
          <Col span={10}>
            <InputNumber value={to} min={from} onChange={val => onChange([from, val])} />
          </Col>
        </Row>
      )
    }
      break

    case 'touchStatus': ele = (
      <CommonSelector
        {...touchStatus}
        mode="multiple"
        filter={{
          // 触达状态与客户意向联动 联动规则 (当客户意向选择了多个时，不联动，一个时，联动）
          intentionId: _.get(formValue, 'intentionId.val.length') === 1 // 这里的intentionId写死了，需要fieldName就为intentionId，不是则无法联动了
            ? _.get(formValue, 'intentionId.val[0]') : undefined,
        }}
      />)
      break
    case 'intention': ele = ({ value, onChange }) => (
      <CommonTreeSelector
        {...customerIntention}
        value={value?.val} // 此时value的type为下面onchange时的{ val, list }，即{ val: number[], list: TreeNodeNormal[] }
        multiple
        onChange={(val: number[], label: string[], list: TreeDataSimple[]) => {
          onChange({ val, list })
        }}
      />
    )
      break
    case 'privateTag':
      ele = (<CommonSelector {...privateTag} />)
      break
    case 'publicTag':
      ele = (<CommonSelector {...publicTag} mode="multiple" />)
      break
    case 'opportunityProducts':
      ele = (<CommonSelector {...opportunityProductsAll} />)
      break
    case 'opportunityProductsCascader':
      ele = (<CommonSelector {...opportunityProductsAll} type="Cascader" multiple={false} />)
      break
    case 'groupId':
      ele = (<CommonSelector {...groupId} />)
      break
    case 'groupName':
      ele = (<CommonSelector {...groupName} />)
      break
    case 'ruleType':
      ele = (<CommonSelector {...ruleTypesNoSession} />)
      break
    case 'extraFormId':
      ele = (<CommonSelector {...extraFormId} />)
      break
    case 'ruleId':
      ele = (<CommonSelector {...ruleId} mode="multiple" />)
      break
    case 'batchId':
      ele = (<CommonSelector {...batchId} mode="multiple" />)
      break
    case 'followType':
      ele = (<CommonSelector {...followType} />)
      break
    case 'sourceTag':
      ele = (<CommonCascador {...sourceTag} />)
      break
    case 'multiTags':
      ele = (
        <Select
          allowClear
          mode="tags"
          placeholder="请输入或直接粘贴多条数据，会自动分割"
          tokenSeparators={[',', '，', ' ', '\t', '\n', '\r']}
          notFoundContent={<span>多条数据以空格、换行或逗号分割</span>}
          maxTagCount={5}
        />)
      break
    case 'maps':
      ele = (
        /**
         * 注意！！！
         * 这里要保证maps[`${item.fieldName}Map`]能够找到，否则的话建议再写一个case
         */
        <Select allowClear placeholder="未筛选则默认全部">
          {Object.entries(maps[`${item.fieldName}Map`] || {}).map(([value, text]) => {
            const temp = +value
            const val = isNaN(temp) ? value : temp
            return <Option key={value} value={val}>{text}</Option>
          })}
        </Select>)
      break
    // 已派发线索页面线索状态
    case 'mapsLeadStatus':
      ele = (
        <Select allowClear placeholder="未筛选则默认全部">
          {Object.entries(leadStatusMap).map(([value, text]) => {
            const val = handleNumber(value)
            const disabled = val === LeadStatusEnum.UNHANDLE || val === LeadStatusEnum.DISCARDED
            return <Option disabled={disabled} key={value} value={val}>{text}</Option>
          })}
        </Select>)
      break
    case 'mapsMulti':
      ele = (
        <Select mode="multiple" allowClear placeholder="未筛选则默认全部">
          {Object.entries(maps[`${item.fieldName}Map`] || {}).map(([value, text]) => {
            const temp = +value
            const val = isNaN(temp) ? value : temp
            return <Option key={value} value={val}>{text}</Option>
          })}
        </Select>)
      break
    case 'company':
      ele = (<CompanySelector />)
      break
    case 'uinIncomeIndustrySelect':
      ele = (<CommonTreeSelector {...uinIncomeIndustry} multiple />)
      break
    default: ele = <p>-</p>
  }
  return ele
}

// 这里必须把用户选择的客户意向区分成一级和二级意向
const formatIntention = (intention: { val: number[], list: TreeDataSimple[] }) => {
  const { val, list } = intention || {}
  const intentionId = val?.filter?.(number => list?.find(i => i.value === number))
  const subIntentionId = val?.filter?.(number => !intentionId?.find(i => i === number))
  return {
    intentionId: isEmptyArray(intentionId) ? undefined : intentionId,
    subIntentionId: isEmptyArray(subIntentionId) ? undefined : subIntentionId,
  }
}

// 线索公海池筛选框
export const unclaimedPageFilterItems: FieldItem[] = [
  { fieldName: 'leadName', label: '线索名称', type: 'input' },
  { fieldName: 'ruleType', label: '任务类型', type: 'ruleType' },
  {
    fieldName: 'sourceTagIds',
    label: '线索来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  {
    fieldName: 'leadTime',
    label: '线索创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadBeginTime: formatTime(val?.[0], 'start'),
      leadEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'phone', label: '电话', type: 'input' }, // 这里phone和qq后端要的都是string类型，所以type只能是input而不能是inputNumber
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'intentionId', label: '客户意向', type: 'intention', handleValue: formatIntention },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  {
    fieldName: 'follower',
    label: '当前跟进人',
    type: 'staffSelectNull',
    handleValue: (val: string[]) => ({
      follower: notEmptyArray(val) ? val?.map(v => v.replace(IDENTIFIER_STRING, '')) : undefined,
    }),
  },
  { fieldName: 'publicTagId', label: '公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '个人标签', type: 'privateTag' },
  { fieldName: 'leadIds', label: '线索id', type: 'inputNumber' },
  {
    fieldName: 'authTime',
    label: '认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      authTimeFrom: formatTime(val?.[0], 'start'),
      authTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'qq', label: 'QQ', type: 'input' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
  {
    fieldName: 'intentionLevel',
    label: '原线索意向',
    render: () => <CommonCascador {...leadIntentionLevel} />,
    handleValue: value => {
      const [intentionLevel, intentionSecondLevel] = value || [undefined, undefined]
      return { intentionLevel, intentionSecondLevel }
    },
  },
  {
    fieldName: 'industryProducts',
    label: '关注产品',
    render: () => <CommonCascador {...industryProducts} changeOnSelect />,
    handleValue: value => {
      const [smallProduct, subProduct] = value || [undefined, undefined]
      return { smallProduct, subProduct }
    },
  },
  {
    fieldName: 'leadTags',
    label: '原线索标签',
    render: () => <CommonSelector {...leadTags} mode="multiple" />,
  },
  { fieldName: 'payType', label: '付费类型', type: 'maps' },
  { fieldName: 'extend', label: '其它信息', type: 'input' },
  {
    fieldName: 'regTime',
    label: '注册时间',
    type: 'rangePicker',
    handleValue: val => ({
      regTimeFrom: formatTime(val?.[0], 'start'),
      regTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'leadReleaseSourceId', label: '来源方线索ID', type: 'input' },
  { fieldName: 'exceedPeriod', label: '是否过期', type: 'maps' },
  {
    fieldName: 'area',
    label: '地域',
    render: () => <CommonCascador {...area} changeOnSelect />,
    handleValue: value => {
      const [provinceCode, cityCode] = value || [undefined, undefined]
      return { provinceCode, cityCode }
    },
  },
]

// 废弃线索池筛选框
export const discardPageFilterItems: FieldItem[] = [
  { fieldName: 'leadName', label: '线索名称', type: 'input' },
  {
    fieldName: 'sourceTagIds',
    label: '线索来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  {
    fieldName: 'leadTime',
    label: '线索创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadBeginTime: formatTime(val?.[0], 'start'),
      leadEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'phone', label: '电话', type: 'input' }, // 这里phone和qq后端要的都是string类型，所以type只能是input而不能是inputNumber
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'intentionId', label: '客户意向', type: 'intention', handleValue: formatIntention },
  { fieldName: 'publicTagId', label: '公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '个人标签', type: 'privateTag' },
  { fieldName: 'leadIds', label: '线索id', type: 'inputNumber' },
  {
    fieldName: 'authTime',
    label: '认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      authTimeFrom: formatTime(val?.[0], 'start'),
      authTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'qq', label: 'QQ', type: 'input' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
  {
    fieldName: 'intentionLevel',
    label: '原线索意向',
    render: () => <CommonCascador {...leadIntentionLevel} />,
    handleValue: value => {
      const [intentionLevel, intentionSecondLevel] = value || [undefined, undefined]
      return { intentionLevel, intentionSecondLevel }
    },
  },
  {
    fieldName: 'industryProducts',
    label: '关注产品',
    render: () => <CommonCascador {...industryProducts} changeOnSelect />,
    handleValue: value => {
      const [smallProduct, subProduct] = value || [undefined, undefined]
      return { smallProduct, subProduct }
    },
  },
  {
    fieldName: 'leadTags',
    label: '原线索标签',
    render: () => <CommonSelector {...leadTags} mode="multiple" />,
  },
  { fieldName: 'payType', label: '付费类型', type: 'maps' },
  { fieldName: 'extend', label: '其它信息', type: 'input' },
  {
    fieldName: 'regTime',
    label: '注册时间',
    type: 'rangePicker',
    handleValue: val => ({
      regTimeFrom: formatTime(val?.[0], 'start'),
      regTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'leadReleaseSourceId', label: '来源方线索ID', type: 'input' },
]

// 线索已分配线索筛选框
export const claimedPageFilterItems: FieldItem[] = [
  { fieldName: 'leadName', label: '线索名称', type: 'input' },
  { fieldName: 'ruleType', label: '任务类型', type: 'ruleType' },
  {
    fieldName: 'sourceTagIds',
    label: '线索来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  {
    fieldName: 'leadTime',
    label: '线索创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadBeginTime: formatTime(val?.[0], 'start'),
      leadEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'phone', label: '电话', type: 'input' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'intentionId', label: '客户意向', type: 'intention', handleValue: formatIntention },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  {
    fieldName: 'follower',
    label: '当前跟进人',
    type: 'staffSelectNull',
    handleValue: (val: string[]) => ({
      follower: notEmptyArray(val) ? val?.map(v => v.replace(IDENTIFIER_STRING, '')) : undefined,
    }),
  },
  { fieldName: 'publicTagId', label: '公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '个人标签', type: 'privateTag' },
  { fieldName: 'leadIds', label: '线索id', type: 'inputNumber' },
  {
    fieldName: 'leadAssignTime',
    label: '线索变更时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadAssignTimeFrom: formatTime(val?.[0], 'start'),
      leadAssignTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'qq', label: 'QQ', type: 'input' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
  {
    fieldName: 'intentionLevel',
    label: '原线索意向',
    render: () => <CommonCascador {...leadIntentionLevel} />,
    handleValue: value => {
      const [intentionLevel, intentionSecondLevel] = value || [undefined, undefined]
      return { intentionLevel, intentionSecondLevel }
    },
  },
  {
    fieldName: 'industryProducts',
    label: '关注产品',
    render: () => <CommonCascador {...industryProducts} changeOnSelect />,
    handleValue: value => {
      const [smallProduct, subProduct] = value || [undefined, undefined]
      return { smallProduct, subProduct }
    },
  },
  {
    fieldName: 'leadTags',
    label: '原线索标签',
    render: () => <CommonSelector {...leadTags} mode="multiple" />,
  },
  { fieldName: 'payType', label: '付费类型', type: 'maps' },
  { fieldName: 'extend', label: '其它信息', type: 'input' },
  {
    fieldName: 'authTime',
    label: '认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      authTimeFrom: formatTime(val?.[0], 'start'),
      authTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'regTime',
    label: '注册时间',
    type: 'rangePicker',
    handleValue: val => ({
      regTimeFrom: formatTime(val?.[0], 'start'),
      regTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'leadStatus',
    label: '线索状态',
    type: 'mapsLeadStatus',
    handleValue: val => ({
      status: val,
    }),
  },
  {
    fieldName: 'firstAssignTime',
    label: '首次分配时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAssignTimeFrom: formatTime(val?.[0], 'start'),
      firstAssignTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'firstAssigner', label: '首次分配人', type: 'staffSelectMulti' },
  {
    fieldName: 'firstFollowTime',
    label: '首次跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstFollowTimeFrom: formatTime(val?.[0], 'start'),
      firstFollowTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'firstFollower', label: '首次跟进人', type: 'staffSelectMulti' },
  { fieldName: 'leadReleaseSourceId', label: '来源方线索ID', type: 'input' },
  { fieldName: 'exceedSla', label: '是否超SLA', type: 'maps' },
  { fieldName: 'exceedPeriod', label: '是否过期', type: 'maps' },
  {
    fieldName: 'area',
    label: '地域',
    render: () => <CommonCascador {...area} changeOnSelect />,
    handleValue: value => {
      const [provinceCode, cityCode] = value || [undefined, undefined]
      return { provinceCode, cityCode }
    },
  },
]

export const formatTimeWithDay = (time: moment.Moment, cut?: 'start' | 'end') => {
  if (!time) return undefined
  if (!cut) return moment(time).format('YYYY-MM-DD')
  if (cut === 'start') return moment(time).startOf('day').format('YYYY-MM-DD')
  if (cut === 'end') return moment(time).endOf('day').format('YYYY-MM-DD')
}

// 电销代下单购物车
export const saasConfigFilterItems: FieldItem[] = [
  {
    fieldName: 'ownerUin',
    label: 'uin',
    type: 'multiTags',
  },
  {
    fieldName: 'selectionId',
    label: '配置单ID',
    type: 'input',
    handleValue: val => ({
      selectionId: Number(val),
    }),
  },
  {
    fieldName: 'spuCode',
    label: '产品',
    render: () => <CommonSelector {...saasProducts} />,
  },
  {
    fieldName: 'saasStatus',
    label: '状态',
    type: 'maps',
    handleValue: val => ({
      status: val && [Number(val)],
    }),
  },
  { fieldName: 'bigDealId', label: '订单ID', type: 'input' },
  { fieldName: 'creator', label: '创建人', type: 'staffSelect' },
  { fieldName: 'operator', label: '归属人', type: 'staffSelect' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createStartTime: formatTimeWithDay(val?.[0], 'start'),
      createEndTime: formatTimeWithDay(val?.[1], 'end'),
    }),
  },
]

// 目标管理筛选框
export const goalFilterItems: FieldItem[] = [
  {
    fieldName: 'belongMonth',
    label: '月份',
    type: 'monthPicker',
    render: () => <MonthPicker disabledDate={current => current > moment().add(3, 'month').startOf('month')} />,
    handleValue: val => ({
      belongMonth: val ? moment(val).startOf('month').format('YYYY-MM') : undefined,
    }),
  },
  {
    fieldName: 'relatedId',
    label: '员工',
    type: 'staffSelect',
    render: () => <CommonSelector {...salesName} valueKey="id" />,
    handleValue: val => ({
      relatedId: val,
      relatedType: isNumber(val) ? GoalRelatedTypeEnum.SALESNAME : undefined,
    }),
  },
]

// 所有任务列表筛选框
export const allTaskListFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'phone', label: '电话', type: 'input' },
  { fieldName: 'ruleType', label: '任务类型', type: 'ruleType' },
  { fieldName: 'taskStatus', label: '任务状态', type: 'maps' },
  {
    fieldName: 'taskCreateTime',
    label: '任务创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      taskCreateBeginTime: formatTime(val?.[0], 'start'),
      taskCreateEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'ruleId', label: '规则名称', type: 'ruleId' },
  { fieldName: 'assignType', label: '任务分配方式', type: 'maps' },
  {
    fieldName: 'taskName',
    label: '任务名称',
    render: () => <CommonSelector {...taskName} />,

  },
  { fieldName: 'taskType', label: '任务进度', type: 'maps' },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'follower', label: '当前跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'sourceTagIds',
    label: '客户来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进记录时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
]

// 跟进记录筛选框
export const followRecordFilterItems: FieldItem[] = [
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'followType', label: '跟进方式', type: 'followType' },
  { fieldName: 'ruleType', label: '任务类型', type: 'ruleType' },
  { fieldName: 'intentionId', label: '客户意向', type: 'intention', handleValue: formatIntention },
  { fieldName: 'touchStatus', label: '触达状态', type: 'touchStatus' },
  { fieldName: 'competingFirm', label: '竞对厂商', type: 'input' },
  {
    fieldName: 'competingExpirationTimeRange',
    label: '竞对到期时间',
    type: 'rangePicker',
    handleValue: val => ({
      competingExpirationBeginTime: formatTime(val?.[0], 'start'),
      competingExpirationEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'competingExpirationProducts',
    label: '竞对到期产品',
    type: 'opportunityProducts',
    render: () => <CommonSelector {...opportunityProducts} mode="multiple" filterOption optionFilterProp="label" />,
  },
  { fieldName: 'creator', label: '历史跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'lastFollowTime',
    label: '跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'taskId', label: '任务ID', type: 'inputNumber' },
  { fieldName: 'leadId', label: '线索ID', type: 'inputNumber' },
  {
    fieldName: 'sourceTagIds',
    label: '客户来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  { fieldName: 'groupId', label: '客户所属组', type: 'groupId' },
  {
    fieldName: 'leadTime',
    label: '线索创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadBeginTime: formatTime(val?.[0], 'start'),
      leadEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'publicTagId', label: '公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '个人标签', type: 'privateTag' },
  { fieldName: 'phone', label: '电话', type: 'input' },
  { fieldName: 'qq', label: 'QQ', type: 'input' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'remark', label: '跟进内容', type: 'input' },
  { fieldName: 'communicateResult', label: '是否有效沟通', type: 'maps' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
]

// 通话记录筛选框
export const callRecordFilterItems: FieldItem[] = [
  { fieldName: 'callNumber', label: '主叫电话', type: 'input' },
  {
    fieldName: 'phone',
    label: '被叫电话',
    render: ({ value, onChange }) => <Input addonBefore="+86" value={value} onChange={e => onChange(e.target.value || undefined)} allowClear />,
    handleValue: val => ({ phone: val ? '+86' + val : undefined }),
  },
  { fieldName: 'salesName', label: '座席姓名', type: 'staffSelect' },
  { fieldName: 'groupId', label: '服务组', type: 'groupId' },
  { fieldName: 'satisfactionResult', label: '满意度', type: 'mapsMulti' },
  {
    fieldName: 'createTime',
    label: '记录生成时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'duration',
    label: '通话时长(秒)',
    type: 'inputNumberRange',
    handleValue: val => ({
      durationFrom: val?.[0],
      durationTo: val?.[1],
    }),
  },
]

// 短信记录筛选框
export const smsRecordFilterItems: FieldItem[] = [
  { fieldName: 'themeId', label: '消息模板ID', type: 'inputNumber' },
  { fieldName: 'contactName', label: '发送对象', type: 'input' },
  { fieldName: 'content', label: '发送内容', type: 'input' },
  { fieldName: 'replyContent', label: '回复内容', type: 'input' },
  {
    fieldName: 'sendTime',
    label: '发送时间',
    type: 'rangePicker',
    handleValue: val => ({
      sendBeginTime: formatTime(val?.[0], 'start'),
      sendEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'replyTime',
    label: '回复时间',
    type: 'rangePicker',
    handleValue: val => ({
      replyTimeFrom: formatTime(val?.[0], 'start'),
      replyTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'operator', label: '操作人', type: 'staffSelect' },
]

// 客户分配记录筛选框 - 菜单页
export const assignRecordFilterItems: FieldItem[] = [
  { fieldName: 'customerName', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'flowType', label: '变更类型', type: 'maps' },
  { fieldName: 'remark', label: '变更原因', type: 'input' },
  { fieldName: 'operator', label: '操作人', type: 'staffSelect' },
  {
    fieldName: 'createTime',
    label: '记录生成时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 专属链接关联记录筛选表单
export const linkUrlFilterItems: FieldItem[] = [
  {
    fieldName: 'relateTime',
    label: '关联时间',
    type: 'datePicker',
    handleValue: val => ({
      relateTime: formatTime(val, 'start')?.slice(0, 10),
    }),
    render: () => <DatePicker disabledDate={current => current && current > moment()} style={{ width: '100%' }} />,
  },
  { fieldName: 'relateUin', label: '触发关联UIN', type: 'multiTags' },
  { fieldName: 'customerName', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'originSales', label: '发起坐席', type: 'staffSelect' },
  { fieldName: 'relateSales', label: '关联坐席', type: 'staffSelect' },
  {
    fieldName: 'status',
    label: '关联状态',
    render: () => (
      <Select allowClear>
        <Select.Option key={1} value={1}>生效中</Select.Option>
        <Select.Option key={0} value={0}>已失效</Select.Option>
      </Select>
    ),
  },
  {
    fieldName: 'endTime',
    label: '到期时间',
    type: 'datePicker',
    handleValue: val => ({
      endTime: formatTime(val, 'end')?.slice(0, 10),
    }),
    render: () => <DatePicker disabledDate={current => current && current > moment().add(7, 'day')} style={{ width: '100%' }} />,
  },
]

// 撞单分配记录 - 菜单页
export const conflictRecordFilterItems: FieldItem[] = [
  {
    fieldName: 'createTime',
    label: '撞单时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'conflictCustomerName', label: '被撞单方客户', type: 'customerNameSelect' },
  { fieldName: 'conflictCid', label: '被撞单方CID', type: 'multiTags' },
  {
    fieldName: 'appealStatus',
    label: '申诉状态',
    type: 'maps',
    handleValue: (val) => ({
      applyStatus: val,
    }),
  },
]

// 客户分配记录筛选框 - 客户详情页
export const customerAssignRecordFilterItems: FieldItem[] = [
  { fieldName: 'flowType', label: '变更类型', type: 'maps' },
  {
    fieldName: 'createTime',
    label: '变更时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 转出客户池筛选框
export const alterFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'follower', label: '转出前跟进人', type: 'staffSelectMulti' },
  { fieldName: 'groupId', label: '转出前所属组', type: 'groupId' },
  {
    fieldName: 'createTime',
    label: '客户创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'transferTime',
    label: '客户转出时间',
    type: 'rangePicker',
    handleValue: val => ({
      transferTimeFrom: formatTime(val?.[0], 'start'),
      transferTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 转出客户池筛选框
export const transTempFilterItems: FieldItem[] = [
  {
    fieldName: 'createTime',
    label: '进入时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'customerName', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
]

// 客户资源池
export const resourceFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'payStatus', label: '付费状态', type: 'maps' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'registerChannelName2', label: '二级注册来源', type: 'registerChannelName2Select' },
  { fieldName: 'registerChannelName3', label: '三级注册来源', type: 'registerChannelName3Select' },
  {
    fieldName: 'follower',
    label: '当前跟进人',
    type: 'staffSelectNull',
    handleValue: (val: string[]) => ({
      follower: notEmptyArray(val) ? val?.map(v => v.replace(IDENTIFIER_STRING, '')) : undefined,
    }),
  },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'lastFollower', label: '最近跟进人', type: 'staffSelectMulti' },
  { fieldName: 'cidSalesChannel', label: 'CID通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'uinSalesChannel', label: 'UIN通路', type: 'cidSalesChannelSelect' },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'predictFollowTime',
    label: '下次跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      predictFollowTimeFrom: formatTime(val?.[0], 'start'),
      predictFollowTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'companyId', label: '所属公司', type: 'company' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'firstPayTime',
    label: '首次付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstPayTimeFrom: formatTime(val?.[0], 'start'),
      firstPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastPayTime',
    label: '最近付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastPayTimeFrom: formatTime(val?.[0], 'start'),
      lastPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'publicTagId', label: '客户公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '客户个人标签', type: 'privateTag' },
  { fieldName: 'itScale', label: 'IT规模', type: 'mapsMulti' },
  { fieldName: 'assignStatus', label: '分配状态', type: 'maps' },
  { fieldName: 'authState', label: '认证状态', type: 'maps' },
  {
    fieldName: 'firstAuthTime',
    label: '首次认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAuthTimeFrom: formatTime(val?.[0], 'start'),
      firstAuthTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'uinIncomeIndustryIds',
    label: 'UIN行业',
    type: 'uinIncomeIndustrySelect',
    handleValue: val => ({ uinIncomeIndustryIds: val?.length ? val : undefined }),
  },
  { fieldName: 'preShouldIncomeLevel', label: '上月应收层级', type: 'maps' },
  { fieldName: 'preShouldIncomeLevelDay', label: '上月应收（日累计）层级', type: 'maps' },
  { fieldName: 'currentShouldIncomeLevelDay', label: '本月应收（日累计）层级', type: 'maps' },
  {
    fieldName: 'shouldIncomePre',
    label: '本月应收预估',
    type: 'maps',
    handleValue: val => val >= 500 ? { shouldIncomePreFrom: val, shouldIncomePreTo: undefined } : { shouldIncomePreTo: val, shouldIncomePreFrom: undefined }, // undefined不能省略，因为setValue是聚合
  },
]
// 公司公海池
export const unclaimedFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'payStatus', label: '付费状态', type: 'maps' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'lastFollower', label: '最近跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cidSalesChannel', label: 'CID通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'uinSalesChannel', label: 'UIN通路', type: 'cidSalesChannelSelect' },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'firstPayTime',
    label: '首次付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstPayTimeFrom: formatTime(val?.[0], 'start'),
      firstPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastPayTime',
    label: '最近付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastPayTimeFrom: formatTime(val?.[0], 'start'),
      lastPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'publicTagId', label: '客户公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '客户个人标签', type: 'privateTag' },
  { fieldName: 'itScale', label: 'IT规模', type: 'mapsMulti' },
  { fieldName: 'assignStatus', label: '分配状态', type: 'maps' },
  { fieldName: 'authState', label: '认证状态', type: 'maps' },
  {
    fieldName: 'firstAuthTime',
    label: '首次认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAuthTimeFrom: formatTime(val?.[0], 'start'),
      firstAuthTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'uinIncomeIndustryIds',
    label: 'UIN行业',
    type: 'uinIncomeIndustrySelect',
    handleValue: val => ({ uinIncomeIndustryIds: val?.length ? val : undefined }),
  },
  { fieldName: 'preShouldIncomeLevel', label: '上月应收层级', type: 'maps' },
  { fieldName: 'preShouldIncomeLevelDay', label: '上月应收（日累计）层级', type: 'maps' },
  { fieldName: 'currentShouldIncomeLevelDay', label: '本月应收（日累计）层级', type: 'maps' },
  {
    fieldName: 'shouldIncomePre',
    label: '本月应收预估',
    type: 'maps',
    handleValue: val => val >= 500 ? { shouldIncomePreFrom: val, shouldIncomePreTo: undefined } : { shouldIncomePreTo: val, shouldIncomePreFrom: undefined }, // undefined不能省略，因为setValue是聚合
  },
]
// 考察客户池
export const investigateFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'payStatus', label: '付费状态', type: 'maps' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'follower', label: '当前跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cidSalesChannel', label: 'CID通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'uinSalesChannel', label: 'UIN通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'lastFollower', label: '最近跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'companyId', label: '所属公司', type: 'company' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'firstPayTime',
    label: '首次付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstPayTimeFrom: formatTime(val?.[0], 'start'),
      firstPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastPayTime',
    label: '最近付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastPayTimeFrom: formatTime(val?.[0], 'start'),
      lastPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'publicTagId', label: '客户公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '客户个人标签', type: 'privateTag' },
  { fieldName: 'itScale', label: 'IT规模', type: 'mapsMulti' },
  { fieldName: 'assignStatus', label: '分配状态', type: 'maps' },
  { fieldName: 'authState', label: '认证状态', type: 'maps' },
  {
    fieldName: 'firstAuthTime',
    label: '首次认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAuthTimeFrom: formatTime(val?.[0], 'start'),
      firstAuthTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'uinIncomeIndustryIds',
    label: 'UIN行业',
    type: 'uinIncomeIndustrySelect',
    handleValue: val => ({ uinIncomeIndustryIds: val?.length ? val : undefined }),
  },
  { fieldName: 'preShouldIncomeLevel', label: '上月应收层级', type: 'maps' },
  { fieldName: 'preShouldIncomeLevelDay', label: '上月应收（日累计）层级', type: 'maps' },
  { fieldName: 'currentShouldIncomeLevelDay', label: '本月应收（日累计）层级', type: 'maps' },
  {
    fieldName: 'shouldIncomePre',
    label: '本月应收预估',
    type: 'maps',
    handleValue: val => val >= 500 ? { shouldIncomePreFrom: val, shouldIncomePreTo: undefined } : { shouldIncomePreTo: val, shouldIncomePreFrom: undefined }, // undefined不能省略，因为setValue是聚合
  },
]
// 关联客户池
export const linkedFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'payStatus', label: '付费状态', type: 'maps' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'follower', label: '当前跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'cidSalesChannel', label: 'CID通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'uinSalesChannel', label: 'UIN通路', type: 'cidSalesChannelSelect' },
  { fieldName: 'lastFollower', label: '最近跟进人', type: 'staffSelectMulti' },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastRelatedTime',
    label: '最近关联时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastRelatedTimeFrom: formatTime(val?.[0], 'start'),
      lastRelatedTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'companyId', label: '所属公司', type: 'company' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  {
    fieldName: 'firstPayTime',
    label: '首次付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstPayTimeFrom: formatTime(val?.[0], 'start'),
      firstPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastPayTime',
    label: '最近付费时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastPayTimeFrom: formatTime(val?.[0], 'start'),
      lastPayTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'publicTagId', label: '客户公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '客户个人标签', type: 'privateTag' },
  { fieldName: 'itScale', label: 'IT规模', type: 'mapsMulti' },
  { fieldName: 'assignStatus', label: '分配状态', type: 'maps' },
  { fieldName: 'authState', label: '认证状态', type: 'maps' },
  {
    fieldName: 'firstAuthTime',
    label: '首次认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAuthTimeFrom: formatTime(val?.[0], 'start'),
      firstAuthTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'uinIncomeIndustryIds',
    label: 'UIN行业',
    type: 'uinIncomeIndustrySelect',
    handleValue: val => ({ uinIncomeIndustryIds: val?.length ? val : undefined }),
  },
  { fieldName: 'preShouldIncomeLevel', label: '上月应收层级', type: 'maps' },
  { fieldName: 'preShouldIncomeLevelDay', label: '上月应收（日累计）层级', type: 'maps' },
  { fieldName: 'currentShouldIncomeLevelDay', label: '本月应收（日累计）层级', type: 'maps' },
  {
    fieldName: 'shouldIncomePre',
    label: '本月应收预估',
    type: 'maps',
    handleValue: val => val >= 500 ? { shouldIncomePreFrom: val, shouldIncomePreTo: undefined } : { shouldIncomePreTo: val, shouldIncomePreFrom: undefined }, // undefined不能省略，因为setValue是聚合
  },
]

// 商机列表
export const saleOpportunityItems: FieldItem[] = [
  { fieldName: 'opportunityId', label: '商机ID', type: 'inputNumber' },
  { fieldName: 'name', label: '商机名称', type: 'input' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'customerStatus', label: '是否转出', type: 'maps' },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'estimateCustomerType', label: '客户预估类型', type: 'maps' },
  {
    fieldName: 'sceneId',
    label: '应用场景',
    type: 'maps',
    render: () => <CommonSelector {...opportunityScene} />,
  },
  {
    fieldName: 'opportunityType',
    label: '商机类型',
    render: () => <CommonSelector {...opportunityType} />,
    handleValue: val => ({ type: val }),
  },
  {
    fieldName: 'opportunityStatus',
    label: '商机状态',
    type: 'maps',
    handleValue: val => ({ status: val }),
  },
  {
    fieldName: 'money',
    label: '商机金额(元)',
    type: 'inputNumberRange',
    handleValue: val => ({
      moneyFrom: val?.[0],
      moneyTo: val?.[1],
    }),
  },
  {
    fieldName: 'customerCategory',
    label: 'EU/ISV',
    render: () => (
      <Select allowClear>
        {Object.entries(customerCategoryTypeMap).map(([value, text]) => {
          const val = handleNumber(value)
          return <Option key={value} value={val}>{text}</Option>
        })}
      </Select>),
  },
  {
    fieldName: 'competitor',
    label: '友商竞争',
    render: () => (
      <Select allowClear>
        {Object.entries(competitorTypeMap).map(([value, text]) => {
          const val = handleNumber(value)
          return <Option key={value} value={val}>{text}</Option>
        })}
      </Select>),
  },
  {
    fieldName: 'competitorMigrateProductIds',
    label: '友商迁移产品',
    render: () => <CommonSelector {...opportunityProducts} mode="multiple" />,
  },
  {
    fieldName: 'uinIncomeIndustryIds',
    label: 'UIN行业',
    type: 'uinIncomeIndustrySelect',
    handleValue: val => ({ uinIncomeIndustryIds: val?.length ? val : undefined }),
  },
  {
    fieldName: 'customerProvinceCode',
    label: '省份',
    render: () => (<CommonSelector {...provinceCode} />),
  },
  {
    fieldName: 'opportunityIsNew',
    label: '是否新客',
    type: 'maps',
    handleValue: val => ({ isNew: val }),
  },
  { fieldName: 'focusProduct', label: '关注产品', type: 'opportunityProductsCascader' },
  {
    fieldName: 'opportunityWinRate',
    label: '赢单率',
    type: 'maps',
    handleValue: val => ({ winRate: isNullOrUndefined(val) ? val : val === 1 ? '1.0' : val + '' }), // 后端需要的是string类型, 1必须是string 的「1.0」
  },
  {
    fieldName: 'estimatedOrderTime',
    label: '预计下单时间',
    type: 'rangePicker',
    handleValue: val => ({
      estimatedOrderTimeFrom: formatTime(val?.[0], 'start'),
      estimatedOrderTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'follower', label: '当前跟进人', type: 'staffSelectMulti' },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'creator', label: '创建人', type: 'staffSelect' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'customerPredictFollowTime',
    label: '下次跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      customerPredictFollowTimeFrom: formatTime(val?.[0], 'start'),
      customerPredictFollowTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'lastModifier', label: '更新人', type: 'staffSelect' },
  {
    fieldName: 'updateTime',
    label: '更新时间',
    type: 'rangePicker',
    handleValue: val => ({
      updateTimeFrom: formatTime(val?.[0], 'start'),
      updateTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 系统任务列表筛选框
export const taskLogFilterItems: FieldItem[] = [
  { fieldName: 'missionType', label: '任务类型', type: 'maps' },
  { fieldName: 'missionId', label: '任务id', type: 'inputNumber' },
  {
    fieldName: 'createTime',
    label: '任务创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 自动处理规则列表筛选项
export const autoExeRuleFilterItems: FieldItem[] = [
  {
    fieldName: 'leadRuleStatus',
    label: '规则状态',
    type: 'maps',
    handleValue: (val) => ({
      status: handleNumber(val),
    }),
  },
  {
    fieldName: 'ruleType',
    label: '规则分类',
    type: 'maps',
    handleValue: (val) => ({
      ruleType: handleNumber(val),
    }),
  },
  {
    fieldName: 'ruleName',
    label: '规则名称',
    type: 'input',
  },
  { fieldName: 'creator', label: '创建人', type: 'staffSelectMulti' },
  { fieldName: 'ruleId', label: '规则ID', type: 'inputNumber' },
  {
    fieldName: 'createTime',
    label: '有效期',
    type: 'rangePicker',
    handleValue: val => ({
      validTimeFrom: formatTime(val?.[0], 'start'),
      validTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'exeAction',
    label: '执行操作',
    type: 'maps',
  },
]

// 线索流转记录筛选框
export const circulateRecordFilterItems: FieldItem[] = [
  { fieldName: 'leadId', label: '线索id', type: 'inputNumber' },
  { fieldName: 'leadName', label: '线索名称', type: 'input' },
  {
    fieldName: 'sourceTagIds',
    label: '线索来源',
    type: 'sourceTag',
    handleValue: val => ({ sourcePrimaryTagId: val?.[0], sourceSecondaryTagId: val?.[1] }),
  },
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'changeType', label: '变更类型', type: 'maps' },
  {
    fieldName: 'createTime',
    label: '变更时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'operator', label: '操作人', type: 'staffSelect' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
]

// 留资记录筛选框
export const retainRecordFilterItems: FieldItem[] = [
  { fieldName: 'extraFormId', label: '模板名称', type: 'extraFormId' },
  { fieldName: 'taskName', label: '所属任务', type: 'input' },
  { fieldName: 'creator', label: '创建人', type: 'staffSelectMulti' },
  { fieldName: 'customerName', label: '客户名称', type: 'customerNameSelect' },
  {
    fieldName: 'createTime',
    label: '创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'leadId', label: '线索ID', type: 'multiTags' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
]

// 批次信息筛选框
export const batchInfoFilterItems: FieldItem[] = [
  { fieldName: 'batchIds', label: '批次id', type: 'batchId' },
  { fieldName: 'batchName', label: '批次名称', type: 'input' },
  { fieldName: 'leadReleaseSource', label: '线索下发方标识', type: 'input' },
  { fieldName: 'leadReleaseOriginMsg', label: '原始信息', type: 'input' },
]

// 组织管理-员工表筛选框
export const organizationStaffFilterItems: FieldItem[] = [
  { fieldName: 'companyId', label: '公司', type: 'company' },
  { fieldName: 'groupId', label: '所属业务组', type: 'groupId' },
  { fieldName: 'salesName', label: '账号名', type: 'input' },
  { fieldName: 'salesNameCh', label: '真实姓名', type: 'input' },
  { fieldName: 'qidianWorkNumber', label: '企点工号', type: 'input' },
  {
    fieldName: 'accountType',
    label: '账号类型',
    type: 'maps',
    handleValue: val => ({
      type: val,
    }),
  },
]

// 组织管理-组织表筛选框
export const organizationGroupFilterItems: FieldItem[] = [
  { fieldName: 'companyId', label: '公司', type: 'company' },
  { fieldName: 'groupName', label: '群组名', type: 'input' },
]

// 操作日志筛选框
export const operationLogFilterItems: FieldItem[] = [
  { fieldName: 'operator', label: '用户名', type: 'staffSelect' },
  {
    fieldName: 'createTime',
    label: '操作时间',
    type: 'monthPicker',
    handleValue: val => ({
      createTime: val ? moment(val).startOf('month').format('YYYY-MM-DD HH:mm:ss') : undefined,
    }),
  },
  {
    fieldName: 'operationLogAction',
    label: '操作类型',
    type: 'maps',
    handleValue: val => ({ action: val }),
  },
]
// 客户管理-解绑客户筛选框
export const unbindCustomerFilterItems: FieldItem[] = [
  { fieldName: 'uin', label: '客户uin', type: 'multiTags' },
  { fieldName: 'agentUin', label: '代理商uin', type: 'multiTags' },
  {
    fieldName: 'reCallTime',
    label: '回复时间',
    type: 'rangePicker',
    handleValue: val => ({
      replyTimeFrom: formatTime(val?.[0], 'start'),
      replyTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  { fieldName: 'unboundStatus', label: '解绑状态', type: 'maps', handleValue: val => ({ status: val }) },
  { fieldName: 'unboundType', label: '解绑方式', type: 'maps' },
]

// 客户管理-解绑客户筛选框
export const bindFailureFilterItems: FieldItem[] = [
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  {
    fieldName: 'bindTime',
    label: '指派时间',
    type: 'rangePicker',
    handleValue: val => ({
      bindTimeFrom: formatTime(val?.[0], 'start'),
      bindTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 指标 客户维度
export const indicatorCustomerFilterItems: FieldItem[] = [
  { fieldName: 'name', label: '客户名称', type: 'customerNameSelect' },
  { fieldName: 'customerType', label: '客户类型', type: 'maps' },
  { fieldName: 'cid', label: 'cid', type: 'multiTags' },
  { fieldName: 'follower', label: '当前跟进人', type: 'staffSelectMulti' },
  { fieldName: 'lastFollower', label: '最近跟进人', type: 'staffSelectMulti' },
  { fieldName: 'publicTagId', label: '公共标签', type: 'publicTag' },
  { fieldName: 'privateTagId', label: '个人标签', type: 'privateTag' },
  {
    fieldName: 'createTime',
    label: '客户创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'firstAuthTime',
    label: '首次认证时间',
    type: 'rangePicker',
    handleValue: val => ({
      firstAuthTimeFrom: formatTime(val?.[0], 'start'),
      firstAuthTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'lastFollowTime',
    label: '最近跟进时间',
    type: 'rangePicker',
    handleValue: val => ({
      lastFollowBeginTime: formatTime(val?.[0], 'start'),
      lastFollowEndTime: formatTime(val?.[1], 'end'),
    }),
  },
]

// 指标 线索维度
export const indicatorLeadFilterItems: FieldItem[] = [
  ...indicatorCustomerFilterItems,
  { fieldName: 'uin', label: 'uin', type: 'multiTags' },
  { fieldName: 'batchId', label: '批次id', type: 'batchId' },
  { fieldName: 'leadId', label: '线索id', type: 'inputNumber' },
  {
    fieldName: 'leadStatus',
    label: '线索状态',
    type: 'mapsMulti',
  },
  {
    fieldName: 'leadAssignTime',
    label: '线索派发时间',
    type: 'rangePicker',
    handleValue: val => ({
      leadAssignTimeFrom: formatTime(val?.[0], 'start'),
      leadAssignTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
]

// 指标 销售机会维度
export const indicatorOpportunityFilterItems: FieldItem[] = [
  ...indicatorCustomerFilterItems,
  {
    fieldName: 'opportunityWinRate',
    label: '赢单率',
    type: 'maps',
    handleValue: val => ({ winRate: isNullOrUndefined(val) ? val : val === 1 ? '1.0' : val + '' }), // 后端需要的是string类型, 1必须是string 的「1.0」
  },
  {
    fieldName: 'estimatedOrderTime',
    label: '预计下单时间',
    type: 'rangePicker',
    handleValue: val => ({
      estimatedOrderTimeFrom: formatTime(val?.[0], 'start'),
      estimatedOrderTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'money',
    label: '机会金额(元)',
    type: 'inputNumberRange',
    handleValue: val => ({
      moneyFrom: val?.[0],
      moneyTo: val?.[1],
    }),
  },
]

// 指标 销售机会任务维度维度
export const indicatorTaskFilterItems: FieldItem[] = [
  ...indicatorCustomerFilterItems,
  { fieldName: 'taskName', label: '任务名称', render: () => <CommonSelector {...taskName} /> },
  { fieldName: 'taskStatus', label: '任务状态', type: 'maps' },
  {
    fieldName: 'taskCreateTime',
    label: '任务创建时间',
    type: 'rangePicker',
    handleValue: val => ({
      taskCreateBeginTime: formatTime(val?.[0], 'start'),
      taskCreateEndTime: formatTime(val?.[1], 'end'),
    }),
  },
]

// 私域关联流程记录-筛选
export const configScrmFlowLogFilterItems: FieldItem[] = [
  {
    fieldName: 'createTime',
    label: '加微时间',
    type: 'rangePicker',
    handleValue: val => ({
      createTimeFrom: formatTime(val?.[0], 'start'),
      createTimeTo: formatTime(val?.[1], 'end'),
    }),
  },
  {
    fieldName: 'addSales',
    label: '添加人',
    type: 'staffSelect',
  },
  {
    fieldName: 'uin',
    label: 'UIN',
    type: 'multiTags',
  },
  { fieldName: 'customerName', label: '客户名称', type: 'customerNameSelect' },
  {
    fieldName: 'cid',
    label: 'CID',
    type: 'multiTags',
  },
  {
    fieldName: 'isAssign',
    label: '是否再分配',
    render: () => (
      <Select options={[{ label: '是', value: '1' }, { label: '否', value: '0' }]} allowClear />
    ),
  },
  {
    fieldName: 'sendStatus',
    label: '发送状态',
    render: () => (
      <Select options={Object.keys(privateSendStatusMap)?.map(o => ({ label: privateSendStatusMap[o], value: Number(o) }))} allowClear />
    ),
  },
]

// 配置管理 - 机器人名单
export const configRobotTaskFilterItems: FieldItem[] = [
  { fieldName: 'id', label: '名单标题', render: () => <CommonSelector {...ListCallRollTitle} /> },
  {
    fieldName: 'status',
    label: '名单状态',
    render: () => (
      <Select allowClear>
        {Object.entries(robotTaskStatusMap).map(([value, text]) => {
          const val = handleNumber(value)
          return <Option key={value} value={val}>{text}</Option>
        })}
      </Select>
    ),
  },
  {
    fieldName: 'sourceType',
    label: '名单来源',
    type: 'mapsMulti',
  },
  { fieldName: 'creator', label: '创建人', type: 'staffSelect' },
]

// 任务管理 - 机器人通话记录
export const robotCallRecordFilterItems: FieldItem[] = [
  { fieldName: 'rollId', label: '名单标题', render: () => <CommonSelector {...ListCallRollTitle} /> },
  { fieldName: 'taskId', label: '任务名称', render: () => <CommonSelector {...robotTaskList} disableItem={null} /> },
  {
    fieldName: 'tags',
    label: '标签',
    render: () => <CommonSelector {...ListCallTag} mode="multiple" />,
  },
  {
    fieldName: 'phone',
    label: '被叫电话',
    render: ({ value, onChange }) => <Input value={value} onChange={e => onChange(e.target.value || undefined)} allowClear />,
  }, {
    fieldName: 'failReason',
    label: '通话结果',
    render: () => (
      <Select allowClear>
        {Object.entries(CallEndReasonMap).map(([value, text]) => {
          const val = handleNumber(value)
          return <Option key={value} value={val}>{text}</Option>
        })}
      </Select>
    ),
  },
  ...callRecordFilterItems.filter(item => !['salesName', 'groupId', 'phone'].includes(item.fieldName)), // 排除坐席和服务组的筛选

]
