/**
 * 客户资源池
 * @author tylerzzheng
 */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { QuestionCircleOutlined, MinusSquareOutlined, PlusSquareOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { AuthChecker, CommonFilters, PageHeader, TableDownload, UserAvatar, CallRobot } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import store from '@store'
import {
  BatchActionFormValues,
  CustomerFilter,
  CustomerItem,
  CustomerType,
  IAccountInfo,
  needTotalEnum,
  ReleaseCustomerFilter,
  UpdateCustomerFilter,
  RobotFilterTypeEnum,
  CallRobotSourceEnum,
} from '@types'
import {
  assignCustomer,
  exportCustomerList,
  getCustomerList,
  mergeCustomerLeadsManually,
  releaseCustomer,
  UpdateCustomer,
} from '@services/customer'
import { antdSortMap, customerTypeMap, payStatusMap, authStateMap, ChannelMarkMap } from '@configs/map'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import BatchAssign, { FormDataType } from '../../../components/Batch/BatchAssign'
import BatchAction from '../../../components/Batch/BatchAction'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { formatTime, getArea, isArray, isNullOrUndefined, notEmptyArray, trimStr } from '@library'
import moment from 'moment'
import { resourceFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
// import QuickAction from '@pages/customer/resource/QuickAction'
// import { presetFilters, presetFilterValues, PresetTypeEnum } from '@pages/customer/resource/presetFilters'
import { storeCustomerQueue } from '@pages/customer/detail/utils'
import { moduleId } from '@configs'

const { Option } = Select
const { confirm, info } = Modal

/* 获取客户所属组 */
const getGroupsText = (groups: CustomerItem['groups']) => {
  const group = []
  groups.reduce((pre, now) => {
    if (now && now.groupName) pre.push(now.groupName)
    return pre
  }, group)
  return group.join(';')
}

export type CustomerData = CustomerItem & {
  customerTypeText?: string,
  provinceNameText?: string,
  cityNameText?: string,
  groupsText?: string,
  payStatusText?: string,
  // lastFollowTimeText?: string,
}

export const getCustomerData = (customerItem: CustomerItem): CustomerData => {
  const customerData: any = _.clone(customerItem)
  // 过滤脏数据 已去掉， 因为排序的问题，直接在render里做了
  // customerData.lastFollowTimeText = customerItem.lastFollowTime === '1971-01-01 00:00:00' ? '' : customerItem.lastFollowTime
  // 翻译
  customerData.customerTypeText = customerTypeMap[customerItem.customerType] || '-'
  customerData.provinceNameText = getArea(null, customerItem.provinceCode)
  customerData.cityNameText = getArea(customerItem.cityCode)
  customerData.groupsText = getGroupsText(customerItem.groups)
  customerData.payStatusText = payStatusMap[customerItem.payStatus] || '-'

  return customerData
}

const initFilterValue = { createTime: [moment().subtract(1, 'month'), moment()] }

const CustomerResourcePage: FunctionComponent = () => {
  const location = useLocation()
  const isPurePoolPage = location?.pathname === '/customers/pure-pool'
  const purePoolInitFilter = isPurePoolPage && { follower: [''] }
  const { userInfo } = store.useSession()

  const initFilter: CustomerFilter = {
    page: 1,
    pageSize: 10,
    needTotal: needTotalEnum.LIST,
    // sort: 'createTime',
    createTimeFrom: formatTime(initFilterValue.createTime[0], 'start'),
    createTimeTo: formatTime(initFilterValue.createTime[1], 'end'),
    // sortType: sortTypeEnum.DESC,
    type: isPurePoolPage ? CustomerType.PURE_COMPANY : CustomerType.NORMAL,
  }

  const {
    loading,
    list,
    reload,
    pagination,
    filter,
    setFilter,
    resetFilter,
    indexList,
    rowSelection,
    selectedRowText,
    rowKey,
  } = useTableService(getCustomerList, {
    ...initFilter,
    ...purePoolInitFilter,
  }, '获取客户列表失败')

  const onTableChange: TableProps<CustomerData>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<CustomerFilter>(sorter)
    setFilter(sort)
  }

  const customerList = useMemo<CustomerData[]>(() => list.map(item => getCustomerData(item)), [list])

  /* 行展开的嵌套table */
  const [expandedIndex, setExpandedIndex] = useState<number>(-1) // 当前展开的行的索引，默认-1表示不展开。同时只能展开一行
  const expandTableColumns: ColumnProps<IAccountInfo>[] = useMemo(() => [
    { title: 'uin', dataIndex: 'uin' },
    { title: '认证状态', dataIndex: 'authState', render: authState => authStateMap[authState] || '-' },
    { title: '注册时间', dataIndex: 'regTime' },
    { title: '一级注册来源', dataIndex: 'registerChannelName1' },
    { title: '二级注册来源', dataIndex: 'registerChannelName2' },
    { title: '三级注册来源', dataIndex: 'registerChannelName3' },
    { title: '四级注册来源', dataIndex: 'registerChannelName4' },
    { title: '认证时间', dataIndex: 'authTime' },
    { title: 'TCC创建时间', dataIndex: 'createTime' },
    {
      title: 'UIN通路',
      dataIndex: 'salesChannels',
      render: (v) => {
        return v?.map(o => o?.salesChannelName1)?.join('；')
      },
    },
    { title: '收入来源', dataIndex: 'channelMark', render: channelMark => ChannelMarkMap[channelMark] || '-' },
    {
      title: 'UIN行业',
      dataIndex: 'uinIncomeIndustry',
      render: uinIncomeIndustry => uinIncomeIndustry
        ? `${uinIncomeIndustry?.first?.name ?? ' '} - ${uinIncomeIndustry?.second?.name ?? ' '}`
        : '-',
    },
    { title: '上月应收层级', dataIndex: 'preShouldIncomeLevel' },
    { title: '上月应收（日累积）层级', dataIndex: 'preShouldIncomeLevelDay' },
    { title: '本月应收（日累积）层级', dataIndex: 'currentShouldIncomeLevelDay' },
    { title: '本月应收预估', dataIndex: 'shouldIncomePre', render: val => Number(val).toFixed(2) },
    { title: '首次付费时间', dataIndex: 'firstPayTime' },
    { title: '最近付费时间', dataIndex: 'lastPayTime' },
  ], [])
  const expandedRowRender = useCallback((record: CustomerItem) => {
    return (
      <Table
        rowKey="uin"
        columns={expandTableColumns}
        dataSource={record?.accountInfo}
        pagination={false}
      />)
  }, [expandTableColumns])

  useEffect(() => setExpandedIndex(-1), [customerList])

  const poolColumns = isPurePoolPage ? [] : [
    { title: '当前跟进人', dataIndex: 'follower', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '当前所属组', dataIndex: 'groupsText', width: 150 },
    { title: '所属公司', dataIndex: 'companyName', width: 150 },
  ]

  const custColumns: TableColumnProps<CustomerData>[] = useMemo(() => [
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 200,
      render: (name, record, index) => {
        const isExpanded = expandedIndex === index // 或者使用record._index也可以
        return (
          <>
            <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
              {trimStr(name, '-')}
            </Link>
            {isArray(record.accountInfo) && (record.accountInfo.length !== 0) && (
              <Tooltip title="多uin客户，请点击图标查看uin详情">
                {isExpanded ? <MinusSquareOutlined className="ml5" onClick={() => setExpandedIndex(isExpanded ? -1 : index)} /> : <PlusSquareOutlined className="ml5" onClick={() => setExpandedIndex(isExpanded ? -1 : index)} />}
              </Tooltip>)}
          </>)
      },
    },
    { title: 'CID', dataIndex: 'cid', width: 200 },
    { title: '客户类型', dataIndex: 'customerTypeText', width: 120 },
    { title: '省份', dataIndex: 'provinceNameText', width: 120 },
    { title: '城市', dataIndex: 'cityNameText', width: 120 },
    ...poolColumns,
    {
      title: '首次付费时间',
      dataIndex: 'firstPayTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'firstPayTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '付费状态', dataIndex: 'payStatusText', width: 180 },
    {
      title: 'CID通路',
      dataIndex: 'salesChannel',
      width: 150,
      render: v => {
        return v && v?.salesChannelName1
      },
    },
    {
      title: (<>标签权重<Tooltip title="客户标签权重为画像标签、公共标签权重求和，每天自动更新"><QuestionCircleOutlined /></Tooltip></>),
      _title: '标签权重',
      dataIndex: 'portraitTagWeight',
      sorter: true,
      sortOrder: filter.sort === 'portraitTagWeight' ? antdSortMap[filter.sortType] : undefined,
      width: 120,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: (<>上月应收层级<Tooltip title="一个客户可能有多个uin且应收层级不同，此处显示最高层级"><QuestionCircleOutlined /></Tooltip></>),
      _title: '上月应收层级',
      dataIndex: 'preShouldIncomeLevel',
      width: 180,
    },
    {
      title: (<>上月应收（日累积）层级<Tooltip title="一个客户可能有多个uin且应收层级不同，此处显示最高层级"><QuestionCircleOutlined /></Tooltip></>),
      _title: '上月应收（日累积）层级',
      dataIndex: 'preShouldIncomeLevelDay',
      width: 180,
    },
    {
      title: (<>本月应收（日累积）层级<Tooltip title="一个客户可能有多个uin且应收层级不同，此处显示最高层级"><QuestionCircleOutlined /></Tooltip></>),
      _title: '本月应收（日累积）层级',
      dataIndex: 'currentShouldIncomeLevelDay',
      width: 180,
    },
    {
      title: (<>本月应收预估<Tooltip title="一个客户可能有多个uin且应收层级不同，此处显示最高层级"><QuestionCircleOutlined /></Tooltip></>),
      _title: '本月应收预估',
      dataIndex: 'shouldIncomePre',
      width: 180,
      render: val => Number(val).toFixed(2),
    },
    { title: '最近一次付费时间', dataIndex: 'lastPayTime', width: 180 },
    { title: '最近跟进人', dataIndex: 'lastFollower', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '最近跟进时间',
      dataIndex: 'lastFollowTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'lastFollowTime' ? antdSortMap[filter.sortType] : undefined,
      render: val => val === '1971-01-01 00:00:00' ? '' : val,
    },
    {
      title: '下次跟进时间',
      dataIndex: 'predictFollowTime',
      width: 180,
    },
    {
      title: '首次认证时间',
      dataIndex: 'firstAuthTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'firstAuthTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
  ], [filter.sort, filter.sortType, expandedIndex, setExpandedIndex])
  const [columnConfigs, columnProps, fr] = useTableColumn(
    isPurePoolPage ? TableIdEnum.CUSTOMER_PURE_POOL : TableIdEnum.CUSTOMER_RESOURCE, custColumns, ['name'],
  )
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  /* 列选择 */
  const selectedCids = useMemo(() => indexList.map(index => _.get(customerList, `[${index}].cid`)) || [], [customerList, indexList])

  /* 分配客户相关 */
  const submitAssign = useCallback(async (formData: FormDataType) => {
    const {
      taskName,
      sales: salesNames,
      isAssignToOfflineSales: ifAssignToOfflineSales,
      remark,
      notifySales,
      extraFormId,
    } = formData

    const payload = {
      filter: { cid: selectedCids },
      taskName,
      salesNames,
      ifAssignToOfflineSales,
      remark,
      notifySales,
      extraFormId,
    }
    const [res, err] = await assignCustomer(payload)
    if (err) {
      message.error(`${err.message}`)
      return true
    }
    if (res && Array.isArray(res?.unAssignedCids) && res?.unAssignedCids?.length > 0) {
      info({
        title: '分配失败',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>客户{res?.unAssignedCids?.map(o => `【${o}】`)}
            分配失败，“考察库”库满，请变更指定座席后再分配
          </>
        ),
        okText: '确认',
      })
      return true
    } else {
      message.success('分配客户成功')
    }
    reload()
  }, [filter, selectedCids])

  /* 释放客户相关 */
  const submitRelease = useCallback(async () => {
    confirm({
      title: `确认释放选中的${selectedCids.length}个客户`,
      async onOk () {
        const payload: ReleaseCustomerFilter = {
          filter: { cid: selectedCids },
        }
        const [, err] = await releaseCustomer(payload)
        if (err) {
          message.error(`释放客户失败${err.message}`)
          return true
        }
        message.success('释放客户成功')
        reload()
      },
    })
  }, [selectedCids, filter])

  /* 打标客户 */
  const submitBatchTag = useCallback(async (formData: BatchActionFormValues) => {
    const { publicTagIds, privateTagIds } = formData
    const payload: UpdateCustomerFilter = {
      filter: { cid: selectedCids },
      updateResult: {
        updateCustomerPublicTags: {
          publicTagIds: _.isEmpty(publicTagIds) ? undefined : publicTagIds,
        },
        updateCustomerPrivateTags: {
          privateTagIds: _.isEmpty(privateTagIds) ? undefined : privateTagIds,
        },
      },
    }
    const [, err] = await UpdateCustomer(payload)
    if (err) {
      message.error(`打标客户失败${err.message}`)
      return true
    }
    message.success('打标客户成功')
    reload()
  }, [selectedCids, filter])

  /* 合并相关 */
  const [customerLoading, setCustomerLoading] = useState(false)
  const [assignRemark, setAssignRemark] = useState<string>('')
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [remainMergeIndex, setRemainMergeIndex] = useState<number>()
  const submitMerge = useCallback(async () => {
    if (!customerList[remainMergeIndex]) {
      message.warning('请选择需要保留的主CID')
      return
    }
    if (!assignRemark) {
      message.warning('请填写合并说明')
      return
    }
    const selectedCustomerNames = indexList?.map(index => _.get(customerList, `[${index}].name`, '')).join('、')
    const remainCid = customerList[remainMergeIndex]?.cid
    const mainCidName = customerList[remainMergeIndex]?.name
    confirm({
      title: `确定要合并客户${selectedCustomerNames}吗？客户合并后，原客户所有线索均转移到主CID：${remainCid}(${mainCidName})下面，客户跟进人也会变为主CID的跟进人，请谨慎操作。`,
      async onOk () {
        const payload = {
          remainCid,
          beMergedCids: selectedCids?.filter(cid => cid !== remainCid),
          remark: assignRemark,
        }
        setCustomerLoading(true)
        const [, err] = await mergeCustomerLeadsManually(payload)
        setCustomerLoading(false)
        if (err) {
          message.error(`客户合并失败${err.message}`)
          return
        }
        message.success('客户合并成功')
        setShowMergeModal(false)
        setAssignRemark('')
        reload()
      },
    })
  }, [indexList, selectedCids, customerList, remainMergeIndex, assignRemark])

  // 开始跟进
  const handleFollow = () => {
    const isSelectOnly = notEmptyArray(indexList)
    const customerIds = isSelectOnly ? indexList.map(index => customerList[index].customerId) : list?.map(item => item?.customerId)

    const customerId = customerIds?.[0]
    if (isNullOrUndefined(customerId)) {
      message.warn('当前无可跟进客户')
      return
    }
    const queue = storeCustomerQueue({
      customerIds: customerIds,
      filter: isSelectOnly ? undefined : filter,
      serviceName: isSelectOnly ? undefined : 'getCustomerList',
    })
    window.open(`/customer/${customerId}?from=${encodeURIComponent(window.location.pathname)}&queue=${queue}`, '_blank')
  }

  const filterRef = useRef()
  // 客户指标
  // const onQuickBtnClick = useCallback((type: PresetTypeEnum) => {
  //   const values = {
  //     ...presetFilterValues[type],
  //     sort: filter?.sort,
  //     sortType: filter?.sortType,
  //     page: 1,
  //     pageSize: filter?.pageSize ?? 10,
  //   }
  //   console.log(type, values)
  //   // @ts-ignore ts没有那么智能， 这里使用的是CommonFilter通过useImperativeHandle暴露出去的方法
  //   filterRef.current && filterRef.current.resetValues(values)
  // }, [presetFilters, filterRef, filter])

  return (
    <>
      <PageHeader title={isPurePoolPage ? '公海池' : '客户资源池'} />
      <Card className="mt20">
        <CommonFilters
          cRef={filterRef}
          className="custom-Label"
          initValue={initFilterValue}
          standstill={9}
          itemList={isPurePoolPage
            ? resourceFilterItems?.filter(o => !['follower', 'groupId', 'companyId'].includes(o?.fieldName))
            : resourceFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter({
            ...initFilter,
            ...purePoolInitFilter,
          })}
        />
      </Card>
      {/* <Card className="mt20" title="客户指标">
        <QuickAction onClick={onQuickBtnClick} />
      </Card> */}
      <Card className="mt20">
        <ConfigTableHeader
          columnConfigs={columnConfigs}
          tableId={isPurePoolPage ? TableIdEnum.CUSTOMER_PURE_POOL : TableIdEnum.CUSTOMER_RESOURCE}
          onSuccess={() => fr()}
        />
        <span className="ml20 mr15">{selectedRowText}</span>
        <AuthChecker points="assignCustomer">
          <BatchAssign
            className="ml10"
            title="分配客户"
            banner={<span>当前已选择{selectedCids.length}个客户，将按下面指定方式分配。</span>}
            onSubmit={submitAssign}
            needRemark
            needNotifySales
          >
            <Button type="primary" disabled={selectedCids?.length <= 0 || loading}>分配客户</Button>
          </BatchAssign>
        </AuthChecker>
        {!isPurePoolPage && (
          <AuthChecker points="releaseCustomer"><Button className="ml10" onClick={submitRelease} disabled={selectedCids?.length <= 0 || loading}>释放客户</Button></AuthChecker>
        )}
        <BatchAction
          type="tag"
          title="打标客户"
          banner={<span>当前已选择{selectedCids.length}个客户。</span>}
          onSubmit={submitBatchTag}
        >
          <Button className="ml10" disabled={selectedCids?.length <= 0 || loading}>打标客户</Button>
        </BatchAction>
        {!isPurePoolPage && (
          <>
            <Button className="ml10" onClick={() => handleFollow()}>开始跟进</Button>
            <AuthChecker points="mergeCustomer">
              <Button
                className="ml10"
                onClick={() => setShowMergeModal(true)}
                disabled={(selectedCids.length <= 1 || selectedCids.length > 3) ?? false}
              >客户合并
              </Button>
            </AuthChecker>
          </>
        )}
        <TableDownload className="ml10" exportMethod={exportCustomerList} filter={filter} />
        <CallRobot
          sourceType={CallRobotSourceEnum.CUSTOMER_LIST}
          filterType={RobotFilterTypeEnum.CUSTOMER}
          selectedCids={selectedCids}
          filterCondition={JSON.stringify({ ...filter, ...(selectedCids?.length > 0 ? { cid: selectedCids } : {}) })}
        />
        <Table
          style={{ maxWidth: 50 + tableXWidth + 'px' }}
          expandedRowRender={expandedRowRender}
          // expandRowByClick
          // expandIconAsCell={false}
          expandIconColumnIndex={-1}
          expandedRowKeys={[expandedIndex]}
          tableLayout="fixed"
          className="mt20 nested-table"
          loading={loading}
          pagination={pagination}
          rowSelection={rowSelection}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          onChange={onTableChange}
          dataSource={customerList}
          // columns={columnProps}
          columns={
            moduleId.COC_TURN_ID === userInfo?.belongModule
              ? columnProps
              : columnProps?.filter((o: any) => o?.dataIndex !== 'portraitTagWeight')
          }
        />
      </Card>
      {showMergeModal && (
        <Modal
          open
          width={650}
          title="客户合并"
          onCancel={() => setShowMergeModal(false)}
          onOk={submitMerge}
          confirmLoading={customerLoading}
          okText="提交"
        >
          <Alert message="客户合并后，原客户所有线索均转移到主CID下面，客户跟进人也会变为主CID的跟进人，请谨慎操作。" type="info" showIcon />
          <Row className="mt15">
            <Col span={7}>
              请选择需要保留的主CID
            </Col>
            <Col span={17}>
              <Select value={remainMergeIndex} onChange={index => setRemainMergeIndex(+index)}>
                {indexList.map(index => (
                  <Option key={index} value={+index}>
                    {`${customerList[+index]?.cid}(${customerList[+index]?.name})`}
                  </Option>))}
              </Select>
            </Col>
          </Row>
          <Row className="mt15">
            <Col span={7}>
              合并说明
            </Col>
            <Col span={17}>
              <Input value={assignRemark} onChange={e => setAssignRemark(e.target.value)} />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  )
}
export default CustomerResourcePage
