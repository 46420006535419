/**
 * 线索分配规则类型
 * @author wzwwu
 */
import { Pagination } from './common'

export interface RuleListFilter {
  page?: number // 页码
  pageSize?: number // 每页数量
  ruleId?: number[] // 规则id
  ruleName?: string // 规则名称（模糊匹配）
  ruleType?: ruleTypeEnum // 规则类型：0 日常任务
  groupId?: number // 职能组id
  status?: RuleStatus // 规则状态：0 未启用，1 启用
  assignSales?: string // 线索分配的员工（模糊查询）
  creator?: Array<string>
  validTimeFrom?: string
  validTimeTo?: string
  exeAction?: ExeActionEnum // 执行操作
}

export enum ExeActionEnum {
  CLAIM_LEAD = 'claimLead',
  ASSIGN_CUSTOMER = 'assignCustomer',
  RECOVER_CUSTOMER = 'recoverCustomer'
}

export interface RuleNameListFilter extends Pagination{
  ruleId?: number | number[] // 规则id
  ruleName?: string // 规则名称（模糊匹配）
  status?: number // 规则状态：0 未启用，1 启用
}

export interface RuleNameList {
  ruleId?: number // 规则id
  ruleName?: string // 规则名称（模糊匹配）
}

/**
 * 线索匹配模式
 */
export enum LeadMatchTypeEnum {
  RULE_MATCH_LEAD = 1, // 1规则匹配线索
  LEAD_MATCH_RULE = 2, // 2线索匹配规则
}

export enum ruleTypeEnum {
  AUTO = -1,
  MANUAl = 0,
}

export interface RuleListItem {
  ruleId: number // 规则 id
  ruleName: string // 规则名
  ruleType: ruleTypeEnum // 规则分类
  ruleTypeName: string // 规则分类名称
  groupIds?: number[] // 线索分配的员工职能组
  status: number // 状态
  assignCheck: number // “一客一渠”校验
  creator: string // 创建人
  createTime: string // 创建时间
  updateTime: string // 更新时间
  belongModule: number // 所属模块
  priority: number // 优先级
  defaultAssignGroupId?: string
  defaultAssignGroupList?: number[]
  // 解析之后是这个类型RuleParamsType
  ruleParams: string // 匹配规则，如 "{\"matchRule\": \"AND\", \"matchConditionList\": [{\"matchKey\": \"lead.status\", \"matchType\": \"equal\", \"matchValue\": \"0\"}]}"
  assignSales?: string[] // 线索分配的员工
  validTime: string // 规则有效期
  notifyCustomer: IsNotifyCustomer
  ruleTimeSlotList: IRuleTimeSlot[] // 生效时间段
  ruleAssignType: RuleAssignTypeEnum // 线索分配比例的方式
  salesAssignRatio?: IAssignRatio[] // 指定人列表的线索分配比例
  groupAssignRatio?: IAssignRatio[] // 指定组列表的线索分配比例
  notifySales: IsNotifySales // 是否通知座席
  leadMatchType: LeadMatchTypeEnum // 线索匹配模式
  forceAssignHasFollowerCustomer: CanForceAssignEnum // 已分配客户是否允许变更跟进人
  ruleAssignLimit: RuleAssignLimitType[] // 下发上限
  ruleAssignDimension?: RuleAssignDimensionType
}

// RuleListItem['ruleParams]是下面类型
export interface RuleParamsType {
  matchRule: RuleParams['matchRule']
  matchConditionList: (Omit<MatchRuleCondition, 'matchValue'> & { matchValue?: any })[]
  executeAction: ExecuteActionEnum
  recoverType?: RecoverTypeEnum
  defaultAssignGroupId?: any
  defaultAssignGroupList?: any
}

export enum CanForceAssignEnum {
  NO = 0,
  YES = 1,
}

export interface RuleAssignLimitType {
  id: number// 105,
  ruleId: number// 95,
  salesName: string// "JinGuoRong",
  totalNum: number// 10, 总上限
  assignNum: number// 7, 已分配上限
  lastModifier: string// "",
  createTime: string// "2021-06-30 17:17:00",
  updateTime: string// "2021-06-30 17:17:00",
  belongModule: number// 7
}

// 规则状态：0 未启用，1 启用
export enum RuleStatus {
  ON = 1,
  OFF = 0,
}

export enum AssignCheck {
  ON = 1,
  OFF = 0,
}

export interface RulePayloadUpdate {
  ruleName: string // 规则名称
  ruleType: number // 规则类型：0 日常任务
  groupIds?: number[] // 线索分配的员工职能组
  status: RuleStatus // 规则状态：0 未启用，1 启用
  assignCheck: AssignCheck // “一客一渠”校验：0 关闭，1 开启
  assignSales?: string[] // 线索分配的员工
  ruleTimeSlotList: IRuleTimeSlot[] // 生效时间段
  ruleAssignType: RuleAssignTypeEnum // 线索分配比例的方式
  salesAssignRatio?: IAssignRatio[] // 指定人分配 - 按线索分配比例 的列表 ， 和ruleAssignLimit互斥，只能有一个
  ruleAssignLimit?: Pick<RuleAssignLimitType, 'salesName' | 'totalNum'>[]// 指定人分配 - 按上限分配 的列表， 和salesAssignRatio互斥，只能有一个
  groupAssignRatio?: IAssignRatio[] // 指定组列表的线索分配比例
  priority: number // 优先级1-100
  ruleParams: RuleParams // 线索筛选条件
  validTime: string // 有效期
  notifyCustomer: IsNotifyCustomer // 是否通知客户
  notifySales: IsNotifySales // 是否通知座席
  leadMatchType: LeadMatchTypeEnum // 线索匹配模式
  extraFormId?: number
  forceAssignHasFollowerCustomer?: CanForceAssignEnum // 已分配客户是否允许变更跟进人
  ruleAssignDimension?: RuleAssignDimensionType //
}

// 分配维度0分配线索1分配客户
export enum RuleAssignDimensionType {
  CLAIM_LEAD = 0,
  ASSIGN_CUSTOMER = 1,
}

// 1客户公海池 2公司公海池
export enum RecoverTypeEnum {
  CUSTOMERS_POOL = 1,
  CUSTOMERS_COMPANY_POOL = 2,
}

export interface RulePayloadAdd extends RulePayloadUpdate {
  ruleId: number // 规则 id
}

// 分配时是否短信通知客户0否1是
export enum IsNotifyCustomer {
  YES = 1,
  NO = 0,
}

// 分配比例
export interface IAssignRatio {
  ruleAssignTab: string | number, // 组id或者saleName
  ruleAssignRatio: number, // 分配比例，0-100
}

// 线索分配比例的方式
export enum RuleAssignTypeEnum {
  RANDOM = 0, // 随机
  CERTAIN = 1, // 指定
}

export interface IRuleTimeSlot {
  timeSlotStart: string,
  timeSlotEnd: string,
}

// 分配时是否短信通知座席0否1是
export enum IsNotifySales {
  YES = 1,
  NO = 0,
}

export interface RuleParams {
  matchRule: 'AND' | 'OR'
  matchConditionList: MatchRuleCondition[]
  executeAction: ExecuteActionEnum
  recoverType?: RecoverTypeEnum
}

// 线索执行的操作
export enum ExecuteActionEnum {
  ASSIGN = 0, // 分配
  RECOVER = 1, // 回收
}

export interface MatchRuleCondition {
  matchKey?: string // 匹配字段
  matchType?: string // 匹配类型
  matchValue?: string // 匹配值
}

export interface LeadRuleCondition {
  label?: string // 名称
  matchType?: string[] // 匹配类型
  tips?: string // 提示
}
