/**
 * 所有用到的权限点，需要在此处先注册
 * 注意： 权限点绝对不能重复
 * @author tylerzzheng
 */
const AUTH_POINT_MAP = {
  /* 路由 菜单 */
  menuTaskList: 'tcc.menu.task.list-r',
  menuTaskAllTask: 'tcc.menu.task.all.task-r',
  menuTaskGoal: 'tcc.menu.task.Goal-r',
  menuTaskFollowRecord: 'tcc.menu.task.follow.record-r',
  menuTaskCallRecord: 'tcc.menu.task.call.record-r',
  menuTaskSmsRecord: 'tcc.menu.task.sms.record-r',
  menuTaskRetainRecord: 'tcc.menu.task.retain.record-r',
  menuLeadListClaimed: 'tcc.menu.lead.list.claimed-r',
  menuLeadListUnclaimed: 'tcc.menu.lead.list.unclaimed-r',
  menuLeadListDiscard: 'tcc.menu.lead.list.discard-r',
  menuLeadAdd: 'tcc.menu.lead.add-r',
  menuLeadCirculateRecord: 'tcc.menu.lead.circulate.record-r',
  menuCustomerOpportunity: 'tcc.menu.customer.opportunity-r',
  menuCustomerDetail: 'tcc.menu.customer.detail-r',
  menuCustomerResource: 'tcc.menu.customer.resource-r',
  menuCustomerPurePool: 'tcc.menu.customer.pure-r',
  menuCustomerCompanyPool: 'tcc.menu.customer.company.pool-r',
  menuCustomerInvestigate: 'tcc.menu.customer.investigate-r',
  menuCustomerLinked: 'tcc.menu.customer.linked-r',
  menuCustomerTransfer: 'tcc.menu.customer.transfer-r',
  menuCustomerTransTemp: 'tcc.menu.customer.trans.temp-r',
  menuCustomerLinkLog: 'tcc.menu.customer.link.log-r',
  menuCustomerFlowLog: 'tcc.menu.scrm.flow.log-r',
  menuCustomerOrderDetail: 'tcc.menu.customer.deal-r',
  menuCustomerAssignRecord: 'tcc.menu.customer.assign.record-r',
  menuCustomerConflictRecord: 'tcc.menu.customer.conflict.record-r',
  menuUnbind: 'tcc.permission.unbind-u',
  menuBindFailure: 'tcc.permission.bind.failure-r',
  menuPerformance: 'tcc.permission.performance-r',
  menuConfigOrganization: 'tcc.menu.config.organization-r',
  menuConfigTags: 'tcc.menu.config.tags-r',
  menuConfigRuleType: 'tcc.menu.config.rule.type-r',
  menuConfigSms: 'tcc.menu.config.sms-r',
  menuConfigLeadRule: 'tcc.menu.config.lead.rule-r',
  menuConfigFollow: 'tcc.menu.config.follow-r',
  menuConfigTaskLog: 'tcc.menu.config.task.log-r',
  menuConfigRetainResourceList: 'tcc.menu.config.retain.resource.list-r',
  menuConfigRetainResourceEdit: 'tcc.menu.config.retain.resource.edit-r',
  menuConfigBatchInfo: 'tcc.menu.config.batch.info-r',
  menuConfigOperationLog: 'tcc.menu.config.operation.log-r',
  menuReportAgentTask: 'tcc.menu.report.agent.task-r',
  menuReportKanban: 'tcc.menu.report.kanban-r',
  menuReportKanbanAdmin: 'tcc.menu.report.kanbanAdmin-r',
  menuRobotCallRecord: 'tcc.menu.task.robot.call.record-r',
  menuRobotTask: 'tcc.menu.config.robot.task-r',
  menuSaasConfig: 'tcc.menu.saas.config-r',
  /* 其它 */
  assignCustomer: 'tcc.permission.customerAssign-u',
  reassignLead: 'tcc.permission.reassignLead-u',
  principalCompany: 'tcc.permission.company.principal-u',
  mgtPublicTag: 'tcc.permission.businessConfig-mgt',
  questionnaireManage: 'tcc.permission.questionnaireManage-mgt',
  transferCustomer: 'tcc.permission.customerTransfer-mgt',
  releaseCustomer: 'tcc.permission.customerRelease-mgt',
  releaseCustomerToCompany: 'tcc.permission.customerRelease.company-mgt',
  mergeCustomer: 'tcc.permission.customerMerge-a',
  importReflux: 'tcc.customer.import.reflux-u',
  readTaskCount: 'tcc.permission.taskCount-r',
  readAllReport: 'tcc.permission.allReport-r',
  uploadLead: 'tcc.permission.leads.upload-mgt',
  // batchUploadLead: 'tcc.permission.leads.batchupload-mgt',
  commentOpportunity: 'tcc.permission.opportunities.comment-u',
  addFollowRecord: 'tcc.permission.addFollowRecord-a',
  simulatedUser: 'tcc.permission.mockLogin-a',
  switchModule: 'tcc.permission.switch.module-mgt',
  checkPhone: 'tcc.permission.customer.phone-r',
  checkOtherContactNumber: 'tcc.permission.customer.email_wechat_qq-r',
  exportOpportunity: 'tcc.permission.opportunities.export-ex',
  mgtGoal: 'tcc.permission.objective-mgt',
  modifyPermission: 'tcc.permission.authority-u',
  robotOutBound: 'tcc.permission.robot.outbound.call-a',
  checkVoucher: 'tcc.permission.voucher-r',
} as const

export default AUTH_POINT_MAP

export type AuthPointType = (keyof typeof AUTH_POINT_MAP)
