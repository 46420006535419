
/**
 * 短信模版相关
 * @author tylerzzheng
 */
import { CommonList, IAddSmsTemplate, IRuleFilter, ISmsTemplate, Pagination, IRule, IEditSmsTemplate } from '@types'
import { requestApi, requestApiV2 } from '@library'

// 获取规则列表
export function getRuleListNoSession (ruleFilter: IRuleFilter & {belongModule: number}) {
  return requestApi<CommonList<IRule>>('GetRuleList', ruleFilter)
}

// 获取短信模版列表
export function getSmsTemplateList (pagination: Pagination) {
  return requestApiV2<CommonList<ISmsTemplate>>('/trpc.tcc.tcc_main.Sms/GetSmsTemplateList', pagination)
}

// 添加短信模版
export function addSmsTemplate (addSmsTemplate: IAddSmsTemplate) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sms/AddSmsTemplate', addSmsTemplate)
}

// 修改短信模版
export function editSmsTemplate (editSmsTemplate: IEditSmsTemplate) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sms/UpdateSmsTemplate', editSmsTemplate)
}

// 修改短信模版
export function deleteSmsTemplate (deletedSmsTemplate: { templateId: number }) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sms/DeleteSmsTemplate', deletedSmsTemplate)
}

// 获取野鹤模板详情 https://iwiki.woa.com/p/4013086023
export function GetYeHeTemplateDetail (params: { yeHeTemplateId : number }) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sms/GetYeHeTemplateDetail', params)
}
